import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { authContext } from '../context/AuthContext';
import { NavBar } from '../components/molecules/navbar';
import PropTypes from 'prop-types';

export const PrivateRoute = ({ Component }) => {
  const { isAuth } = useContext(authContext);
  return isAuth
    ? (
    <div className="page">
      <NavBar />
      {Component}
    </div>
      )
    : (
    <Navigate to="/" />
      );
};

PrivateRoute.propTypes = {
  Component: PropTypes.element
};
