import React, { useState, useContext, useEffect, Fragment, useRef } from 'react';

import * as _ from 'lodash';
import { authContext } from '../../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';

import { Chart as ChartJS, ArcElement, CategoryScale, LogarithmicScale, LinearScale, BarElement, Tooltip, Legend, Title } from 'chart.js';
import ChartjsPluginStacked100 from 'chartjs-plugin-stacked100';

import { Bar } from 'react-chartjs-2';

import './styles.siteStats.scss';
import { StatsToolBar } from '../../molecules/stats-toolbar';
import { siteService } from '../../../services/siteService';
import { recommendationService } from '../../../services/recommendationService';
import { titleService } from '../../../services/titleService';
import { useModels } from '../../../hooks/useModels';
import { Spinner } from '../../atoms/spinner';
import {
  FLRID_FILTER,
  ML_TARGET,
  POPULARITY
} from '../../../constants/constants';

ChartJS.register(ArcElement, CategoryScale, LinearScale, LogarithmicScale, BarElement, ChartjsPluginStacked100, Tooltip, Legend, Title);

export const CHART_COLORS = {
  black: '#000000',
  orange: '#E69F00',
  blue: '#56B4E9',
  green: '#009E73',
  yellow: '#F0E442',
  darkBlue: '#0072B2',
  red: '#D55E00',
  pink: '#CC79A7'
};

const barOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart'
    }
  },
  scales: {
    x: {
      display: true,
      ticks: {
        callback: labelSize
      }
    },
    y: {
      display: true,
      type: 'logarithmic'
    }
  }
};

export const barConfig = {
  labels: [],
  datasets: []
};

let stackedBarCharts = [];
let barCharts = [];
const topLevelBisacs = ['JUVENILE FICTION', 'JUVENILE NONFICTION',
  'COOKING', 'SCIENCE', 'HISTORY', 'RELIGION', 'NATURE', 'BODY, MIND & SPIRIT', 'TRAVEL', 'FICTION',
  'LANGUAGE ARTS & DISCIPLINES', 'REFERENCE', 'STUDY AIDS', 'COMICS & GRAPHIC NOVELS',
  'TRANSPORTATION', 'CRAFTS & HOBBIES', 'PETS', 'HUMOR', 'PSYCHOLOGY', 'SPORTS & RECREATION'
];

const currentBisacMap = {};
const colorLen = Object.values(CHART_COLORS).length;
let lastColorIndex = 0;

// function createCondensedLabel (label) {
//   let newLabel = label;
//   const replaceMap = {
//     'JUVENILE FICTION': 'JF',
//     'JUVENILE NONFICTION': 'JNF',
//     FICTION: 'FIC',
//     'SOCIAL SCIENCE': 'SS',
//     '/ General': '/ G'
//   };
//   Object.entries(replaceMap).forEach(([k, v]) => {
//     if (label.includes(k)) {
//       newLabel = newLabel.replace(k, v);
//     }
//   });
//   const splitVals = newLabel.split(' / ');
//   if (newLabel.includes('JF') || newLabel.includes('JNF')) {
//     newLabel = splitVals[0] + '-' + splitVals[1];
//   } else {
//     newLabel = splitVals[0];
//   }
//   return newLabel;
// }

function onClickStackedBarHandler (e, elements, ref) {
  const dataset = stackedBarCharts[0].data.datasets.filter((x) => x.stack === 'recs_stats' && x.data[elements[0].index] > 0);
  let subdataset = dataset.sort((x, y) => y.data[0] - x.data[0]).slice(0, 20);

  if (subdataset.length < 20) {
    const alreadySeenLabels = new Set(subdataset.map((x) => x.label));
    const dataset2 = stackedBarCharts[0].data.datasets.filter((x) => !alreadySeenLabels.has(x.label) && x.data[elements[0].index] > 0);
    const subdataset1 = dataset2.sort((x, y) => y.data[0] - x.data[0]).slice(0, 20 - subdataset.length);
    subdataset = subdataset.concat(subdataset1);
  }

  const subdataKeys = subdataset.map((x) => x.label);
  const newDataMap = {};
  const subBarOptions = _.cloneDeep(barOptions);
  stackedBarCharts[0].data.datasets.forEach((datset) => {
    const labelIndex = subdataKeys.findIndex((label) => label === datset.label && datset.data[elements[0].index] > 0);

    if (labelIndex > -1) {
      let arr = null;
      if (newDataMap[datset.stack]) {
        arr = newDataMap[datset.stack].data;
        arr[labelIndex] = datset.data[elements[0].index];
        newDataMap[datset.stack].data = arr;
      } else {
        arr = new Array(subdataKeys.length).fill(0);
        arr[labelIndex] = datset.data[elements[0].index];
        const color = findNextColorByBisac(datset.stack);
        newDataMap[datset.stack] = {
          data: arr,
          label: datset.stack,
          backgroundColor: color,
          borderColor: color
        };
      }
      subdataset.push(datset);
    }
  });

  ref.config.data = { labels: subdataKeys, datasets: Object.values(newDataMap) };
  // subBarOptions.scales.y.type = 'linear';
  subBarOptions.plugins.title.text = `${topLevelBisacs[elements[0].index].toUpperCase()}`;
  ref.config.options = subBarOptions;
  ref.update();
}

export const stackedBarOptions = {
  onClick: onClickStackedBarHandler,
  plugins: {
    title: {
      display: true
    },
    tooltip: {
      enabled: true,
      filter: function (context) {
        return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
      },
      callbacks: {
        beforeLabel: function (context) {
          // https://github.com/chartjs/Chart.js/issues/6025
          const ds = context.chart.data.datasets;
          return ds[context.datasetIndex].stack.toUpperCase();
        },
        label: function (context) {
          return (context.dataset.label || '') + ': ' + context.parsed.y + '%';
        }
      }
    },
    legend: {
      display: false
    },
    stacked100: { enable: true, replaceTooltipLabel: false }
  },
  responsive: true,
  maintainAspectRatio: false,
  // aspectRatio: '1|3',
  interaction: {
    // mode: 'index',
    intersect: false
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        callback: labelSize
      }
    },
    y: {
      stacked: true
      // type: 'logarithmic'
    }
  }
};

function labelSize (value, index, ticks) {
  const characterLimit = 20;
  const label = this.getLabelForValue(value);
  if (label.length >= characterLimit) {
    return label.slice(0, label.length).substring(0, characterLimit - 1).trim() + '...';
  }
  return label;
}

function findNextColorByBisac (bisacName, bisacKey) {
  if (currentBisacMap[bisacName + bisacKey] === undefined) {
    currentBisacMap[bisacName + bisacKey] = lastColorIndex;
    lastColorIndex += 1;
  }
  return Object.values(CHART_COLORS)[currentBisacMap[bisacName + bisacKey] % colorLen];
}

function findTopLevelBisacs (statTypes, statname, data) {
  const bisacLabels = new Set();
  statTypes.forEach((statType) => {
    if (data[statType]) {
      const bisacsStats = data[statType][statname];
      Object.keys(bisacsStats).forEach((label, index) => {
        const splitPaths = label.split(' / ');
        bisacLabels.add(splitPaths[0]);
      });
    }
  });
  return [...bisacLabels];
}

function buildStackedBarChart (statTypes, statname, data) {
  const seenTopLevelBisacIndexes = new Set();
  // Reset color index
  lastColorIndex = 0;
  const options = _.cloneDeep(stackedBarOptions);
  const labelDataMap = {};
  const bisacLabels = findTopLevelBisacs(statTypes, statname, data);
  statTypes.forEach((statType) => {
    if (data[statType]) {
      let bisacsStats = data[statType][statname];
      const entries = Object.entries(bisacsStats);
      bisacsStats = Object.fromEntries(entries.sort((x, y) => y[1] - x[1]));
      Object.keys(bisacsStats).forEach((label, index) => {
        const splitPaths = label.split(' / ');
        const topLevelBisac = splitPaths[0];
        const secondLevelBisac = splitPaths.splice(1).join('/');
        const bisacKey = topLevelBisac + '_' + secondLevelBisac + '_' + statType;
        const bisacIndex = bisacLabels.findIndex((topBisac) => topBisac === topLevelBisac.toUpperCase());
        seenTopLevelBisacIndexes.add(bisacIndex);
        // if (bisacIndex === 1 || bisacIndex === 0) {
        if (labelDataMap[bisacKey]) {
          const data1 = labelDataMap[bisacKey];
          data1.data[bisacIndex] = bisacsStats[label];
          labelDataMap[bisacKey].data = data1.data;
        } else {
          const arr = new Array(bisacLabels.length).fill(0);
          arr[bisacIndex] = bisacsStats[label];
          const color = findNextColorByBisac(topLevelBisac, secondLevelBisac);
          labelDataMap[bisacKey] = {
            label: label.split(' / ').splice(1).join('/'),
            data: arr,
            backgroundColor: color,
            borderColor: color,
            stack: statType
          };
        }
      });
    }
  });
  options.plugins.title.text = `${statname.toUpperCase()}`;
  return { data: { labels: bisacLabels, datasets: Object.values(labelDataMap) }, options };
}

function buildBarChart (statTypes, statname, data) {
  if (statTypes) {
    const options = _.cloneDeep(barOptions);
    const barLabels = new Set();
    statTypes.forEach(statType => {
      if (data[statType]) {
        const stats = data[statType][statname];
        Object.keys(stats).map(value => barLabels.add(value));
      }
    });
    const datasets = [];
    statTypes.forEach((statType, index) => {
      if (data[statType]) {
        const stats = data[statType][statname];
        const dataList = [];
        barLabels.forEach((label) => {
          if (stats[label]) {
            dataList.push(stats[label]);
          } else {
            dataList.push(0);
          }
        });
        options.plugins.title.text = `${statname}`.toUpperCase();
        datasets.push(
          {
            label: `${statname} by ${statType}`,
            data: dataList,
            backgroundColor: Object.values(CHART_COLORS)[index]
          }
        );
      }
    });
    if (datasets.length > 0) {
      return {
        data: {
          labels: [...barLabels],
          datasets
        },
        options
      };
    }
  }
}

export const DistrictStats = () => {
  const { siteGuid, appId } = useContext(authContext);

  const {
    models,
    modelTypeOptions,
    modelsBasedOnType,
    updateModelTypeOptions,
    updteModelsBasedOnType
  } = useModels();
  const [model, setModel] = useState();
  const [modelType, setModelType] = useState();
  const bisacStackedRef = useRef(null);
  const [loading, setLoading] = useState(false);

  // const [districtStatsData, setDistrictStatsData] = useState({});
  // const [recommendationData, setRecommendationData] = useState({});
  // const [titlesData, setTitlesData] = useState([]);
  // const [errorMessage, setErrorMessage] = useState();
  const [, setDistrictStatsData] = useState({});
  const [, setRecommendationData] = useState({});
  const [, setTitlesData] = useState([]);
  const [, setErrorMessage] = useState();

  const updateModel = (option) => {
    const newModel = models.find((model) => {
      return model.guid === option.value;
    });

    setModel(newModel);
  };

  const updateTypeModel = (option) => {
    setModelType(option);
  };

  useEffect(
    function () {
      updateModelTypeOptions(ML_TARGET, [POPULARITY]);
    },
    [models]
  );

  useEffect(
    function () {
      updateModelTypeOptions(ML_TARGET, [POPULARITY]);
    },
    [models]
  );

  useEffect(
    function () {
      setModelType(modelTypeOptions[0]);
    },
    [modelTypeOptions]
  );

  useEffect(
    function () {
      const defaultType = modelType ? modelType.value : undefined;
      updteModelsBasedOnType(ML_TARGET, defaultType);
    },
    [modelType]
  );

  useEffect(
    function () {
      setModel(modelsBasedOnType[0]);
    },
    [modelsBasedOnType]
  );

  function getFLRIDArray (matrix) {
    if (matrix) {
      return matrix.map((rec) => {
        return rec.flrid;
      });
    } else {
      return [];
    }
  }

  const getRecs = () =>
    new Promise((resolve, reject) => {
      recommendationService
        .getRecommendations(model, siteGuid, appId)
        .then((data) => {
          setErrorMessage();
          setRecommendationData(data);
          const flridArray = getFLRIDArray(data.recs);
          return flridArray;
        })
        .then((flridArray) => {
          return titleService.getTitlesByFilter(flridArray, FLRID_FILTER);
        })
        .then((data) => {
          setTitlesData(data);
          resolve(data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
          setErrorMessage('Could not fetch recommendations for this site, Please try another model or site');
          setRecommendationData([]);
          setTitlesData([]);
        });
    });

  function buildRecStats (data) {
    const res = {};
    ['follett_interest_level', 'lang'].forEach((field) => {
      res[field] = data.reduce((accumulator, currentValue, index) => {
        const key = currentValue[field] ? currentValue[field] : 'NA';
        if (accumulator[key]) {
          accumulator[key] = accumulator[key] + 1;
        } else {
          accumulator[key] = 1;
        }
        return accumulator;
      }, {});
    });

    res.bisacs = data.reduce((accumulator, currentValue, index) => {
      currentValue.bisacs.forEach(bisac => {
        if (accumulator[bisac.description]) {
          accumulator[bisac.description] = accumulator[bisac.description] + 1;
        } else {
          accumulator[bisac.description] = 1;
        }
      });

      return accumulator;
    }, {});
    return res;
  }

  useEffect(
    function () {
      if (model && siteGuid) {
        setLoading(true);
        // const labels = ['site_catalog_stats', 'site_checkout_stats'];
        const regex = /([\d]+000000)/gm;
        const found = model.guid.match(regex);
        const trainingTimestamp = found && found.length > 0 ? found[0] : undefined;
        const getSitestatsAndRecs = Promise.allSettled([siteService.getSiteStats(siteGuid, trainingTimestamp), getRecs()]);
        getSitestatsAndRecs
          .then(([res1, res2]) => {
            setErrorMessage();
            let data;
            if (res1.status === 'fulfilled') {
              data = res1.value;
            } else {
              throw new Error(res1.value);
            }
            setDistrictStatsData(data);
            if (res2.status === 'fulfilled') {
              data.recs_stats = buildRecStats(res2.value);
            }
            const labels = Object.keys(data);
            // Build Stack Charts
            stackedBarCharts = [buildStackedBarChart(labels, 'bisacs', data)];

            // Build Bar Charts
            barCharts = [];
            ['follett_interest_level', 'lang'].forEach((stat) => {
              const barData = buildBarChart(labels, stat, data);
              if (barData) {
                barCharts.push(barData);
              }
            });

            return data;
          })
          .catch((error) => {
            console.error(error);
            setErrorMessage('Could not fetch recommendations for this site, Please try another model or site');
            // RESET STUFF!
            setDistrictStatsData([]);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [model, siteGuid] // empty-once, set variables-refresh based on var changes
  );

  const resetChart = () => {
    const bisacStackedChart = bisacStackedRef.current;
    bisacStackedChart.config.data = stackedBarCharts[0].data;
    bisacStackedChart.config.options = stackedBarOptions;
    bisacStackedChart.update();
  };

  return (
    <Fragment>
      <StatsToolBar
        modelOptions={modelsBasedOnType}
        selectedModel={model}
        onChangeModel={updateModel}
        modelTypeOptions={modelTypeOptions}
        selectedType={modelType}
        onChangeModelType={updateTypeModel}
      />
      {!loading && (
        <div className="row">
          <div className="label">
            <h3>Site Stats</h3>
          </div>
          <div className="flex-column">
            <ul>
              <li>Catalog, Checkout and Training checkout counts consider only the top 20 bisacs by counts</li>
              <li>Recommendations consider the top 50 bisacs by score</li>
              <li>Labels on the x-axis are the top level bisacs according to bisac.org</li>
              <li>Each stacked bar group is clickable and show additional drilldowns based on top sub bisacs for the top level for the recommendations</li>
              <li>Colors are fixed only for a the stacked bars within a group(top level bisac)</li>
            </ul>
            {(stackedBarCharts.length > 0) &&
              stackedBarCharts.map((stackedBarChart, index) => {
                return <div key={stackedBarChart.data.datasets[0].label} className="stacked-bar-chart">
                  <Bar ref={bisacStackedRef} className="myChart" data={stackedBarChart.data} options={stackedBarChart.options} />
                </div>;
              })}
            <button className="reset" onClick={resetChart}>Reset <FontAwesomeIcon icon={faRotateLeft} /></button>
          </div>
          <div className="bar-container">

            {(barCharts.length > 0) &&
              barCharts.map((barData) => {
                return <div key={barData.data.datasets[0].label} className="bar-chart"><Bar data={barData.data} options={barData.options} /></div>;
              })}
          </div>

        </div>
      )}
      {loading && <Spinner></Spinner>}
    </Fragment>
  );
};
