import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Nav } from 'react-bootstrap';
import { PanelCompareStatistics } from '../../organisms/panel-compare-statistics';
import { METRICS_TAB, PARAMS_TAB } from '../../../constants/constants';

export const MetricsModal = (props) => {
  const { show, model, modelName, handleClose } = props;

  const paramsKeys = model.params ? Object.keys(model.params) : [];
  const metricsKeys = model.metrics ? Object.keys(model.metrics) : [];
  model.label = modelName;

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Body className="show-grid">
        <Tab.Container id="left-tabs-example" defaultActiveKey={PARAMS_TAB}>
          <Row>
            <Col sm={2}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item className="border rounded mb-1">
                  <Nav.Link className="text-center" eventKey={PARAMS_TAB}>
                    Params
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="border rounded mb-1">
                  <Nav.Link className="text-center " eventKey={METRICS_TAB}>
                    Metrics
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="border rounded">
                  <Button
                    className="w-100"
                    variant="secondary"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey={PARAMS_TAB}>
                  <PanelCompareStatistics
                    keySet={paramsKeys}
                    compareMatrix={[model]}
                    statisticsAttribute={PARAMS_TAB}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey={METRICS_TAB}>
                  <PanelCompareStatistics
                    keySet={metricsKeys}
                    compareMatrix={[model]}
                    statisticsAttribute={METRICS_TAB}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
};

MetricsModal.propTypes = {
  show: PropTypes.bool,
  model: PropTypes.object,
  modelName: PropTypes.string,
  handleClose: PropTypes.func
};
