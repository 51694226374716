import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';
import { CollapseCardItem } from '../collapse-card-item';
import { SeriesTabs } from '../serie-tabs';
import noTitleThumbnail from '../../../../../public/images/titleNotFound.png';

import './styles.row.series.scss';
import { BOOK_IMAGE_URL } from '../../../constants/enviroment';

export const RowSeries = (props) => {
  const { cardItem, tabItem, panelRow, mode } = props;
  const [selectedItem, setselectedItem] = useState();

  useEffect(() => {
    setselectedItem();
  }, [panelRow]);

  const handleOnCardClick = (selectedItemRow) => {
    panelRow[selectedItemRow].open = !panelRow[selectedItemRow].open;
    panelRow[selectedItemRow].position = selectedItemRow;

    panelRow.forEach((item, index) => {
      if (index !== selectedItemRow) {
        panelRow[index].open = false;
      }
    });

    setselectedItem({ ...panelRow[selectedItemRow] });
  };

  const canCollapse = (rowItemIndex, currentRow) => {
    return selectedItem &&
    (((rowItemIndex + 1) % 6 === 0 && Math.floor(selectedItem.position / 6) === currentRow) ||
    (rowItemIndex === panelRow.length - 1 && (Math.floor(selectedItem.position / 6) === Math.floor(panelRow.length / 6))));
  };

  const isOpen = (rowItemIndex) => {
    return selectedItem && selectedItem.open && selectedItem.position === rowItemIndex;
  };

  return (
    <div className="series-grid-content ">
      <div className="serie-grid-items">
        {panelRow.map((rowItem, rowItemIndex) => {
          const image_url = getImage(rowItem);
          const currentRow = Math.floor((rowItemIndex) / 6);

          return (
            <Fragment key={`${rowItem.title}-${rowItemIndex}`}>
              <CollapseCardItem
                isOpen={isOpen(rowItemIndex)}
                title={rowItem.title}
                image_url={image_url}
                fil={rowItem.fil}
                rowItemIndex={rowItemIndex}
                onClick={handleOnCardClick}
              />

              {canCollapse(rowItemIndex, currentRow) && (
                <div className="collapse-container">
                  <Collapse in={selectedItem.open}>
                    <div>
                      <hr />
                      <SeriesTabs
                        mode={mode}
                        selectedCardItem={selectedItem}
                        cardItem={cardItem}
                        tabItem={tabItem}
                      />
                      <hr />
                    </div>
                  </Collapse>
                </div>
              )}
            </Fragment>
          );
        })}
      </div>

    </div>
  );
};

function getImage (seriesSet) {
  let image_url = noTitleThumbnail;

  if (seriesSet) {
    const titles = seriesSet.titles;
    const firstTitle = titles.length > 0 ? titles[0] : null;

    if (firstTitle) {
      const firstIsbn = firstTitle.isbn_13s[0][0];
      image_url = `${BOOK_IMAGE_URL}${firstIsbn}`;
    }
  }

  return image_url;
}

RowSeries.propTypes = {
  mode: PropTypes.string,
  cardItem: PropTypes.func,
  tabItem: PropTypes.func,
  panelRow: PropTypes.array,
  panelRowIndex: PropTypes.number
};
