import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { TitlePanel } from '../../organisms/panel-title';

export const TitleModal = (props) => {
  const { show, titleData, handleClose } = props;

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        {titleData && <Modal.Title>{titleData.title}</Modal.Title>}
      </Modal.Header>
      <Modal.Body className="show-grid">
        {titleData
          ? (
          <TitlePanel title={titleData} />
            )
          : (
          <div>Data not available.</div>
            )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

TitleModal.propTypes = {
  show: PropTypes.bool,
  titleData: PropTypes.object,
  handleClose: PropTypes.func
};
