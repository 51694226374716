import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';

export const TabItemNewSeries = (props) => {
  const { formats, setFlrids } = props;

  return formats.map((format, groupIndex) => {
    return (
      <Nav.Item key={groupIndex} className="serie-tab-pills">
        <Nav.Link eventKey={groupIndex}>
          <span>{`Set FLRID: ${setFlrids[groupIndex]}`}</span>
          <br />
          <span className="small-font">{format}</span>
          <br />
        </Nav.Link>
      </Nav.Item>
    );
  });
};

TabItemNewSeries.propTypes = {
  formats: PropTypes.array,
  setFlrids: PropTypes.array
};
