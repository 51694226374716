import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import { Spinner } from '../../atoms/spinner';
import { authenticationService } from '../../../services/authenticationService';

import { authContext } from '../../../context/AuthContext';
import {
  SITE_GUID,
  CODE,
  ERROR,
  APP_ID,
  CANCEL,
  LOGIN
} from '../../../constants/constants';

import './styles.login.scss';

export const LogIn = () => {
  const [loading, setLoading] = useState(true);
  const [loginError, setLoginError] = useState(undefined);

  const [searchParams] = useSearchParams();
  const { isAuth, activateAuth } = useContext(authContext);
  const navigate = useNavigate();

  useEffect(function () {
    const code = searchParams.get(CODE);
    const error = searchParams.get(ERROR);
    const appId = searchParams.get(APP_ID);
    const siteGuid = searchParams.get(SITE_GUID);

    if (isAuth) {
      navigate('/dashboard');
      setLoading(false);
    } else if (error) {
      console.log('Error when login', error);
      setLoginError(error);
      setLoading(false);
    } else if (code) {
      if (code === CANCEL) {
        const url = authenticationService.getLoginPage(
          null,
          null,
          LOGIN,
          true,
          null
        );
        setLoading(false);
        window.location.replace(url);
      }

      async function loginUser () {
        await fetchData(code);
        setLoading(false);
        navigate('/dashboard');
      }

      loginUser();
    } else {
      const url = authenticationService.getLoginPage(
        appId,
        siteGuid,
        LOGIN,
        true,
        null
      );
      setLoading(false);
      window.location.replace(url);
    }
  }, []);

  const fetchData = async (code) => {
    const data = await authenticationService.redeemToken(code);
    await activateAuth(data);
  };

  const onRedirectLogin = (model) => {
    setLoginError(undefined);
    window.location.href = '/';
  };

  return (
    <React.Fragment>
      {loading && <Spinner></Spinner>}

      {loginError && (
        <div className="login">
          <br />
          something gets wrong:
          <br />
          <span className="text-danger">{loginError}</span>
          <br />
          <Button variant="secondary" onClick={onRedirectLogin}>
            Login
          </Button>
        </div>
      )}
    </React.Fragment>
  );
};
