import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { authenticationService } from '../../../services/authenticationService';
import { authContext } from '../../../context/AuthContext';

export const LogoutButton = (props) => {
  const { removeAuth } = useContext(authContext);
  const navigate = useNavigate();

  const onLogoutUser = () => {
    authenticationService.logoutUser();
    removeAuth();
    navigate('/');
  };

  return (
    <React.Fragment>
      <a className="dropdown-item" onClick={onLogoutUser}>
        Logout
      </a>
    </React.Fragment>
  );
};
