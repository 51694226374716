import React from 'react';
import PropTypes from 'prop-types';
// import Table from 'react-bootstrap/Table';
import { CompareItem } from '../../atoms/compare-item';
import { titleService } from '../../../services/titleService';
import { ListWithModal } from '../../atoms/list-with-modal';
import { modelService } from '../../../services/modelService';

import './styles.compare-list.scss';

export const CompareList = (props) => {
  const { model, titlesData } = props;
  return (
    <slot className="compare-list list-view">
      <div className="title">
      {modelService.getModelName(model)}
      </div>
        {model.recs && (
            <ListWithModal
              Component={CompareItem}
              array={model.recs}
              searchArray={titlesData}
              findItem={'isbn_13'}
              findTitleFunction={titleService.findSerieTitleByIsbn13orKey}
            />
        )}
    </slot>
  );
};

CompareList.propTypes = {
  model: PropTypes.object,
  titlesData: PropTypes.array
};
