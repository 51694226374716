import * as _ from 'lodash';
import mem from 'mem';
import { SERVER_REQUEST_HOST } from '../constants/enviroment';

export const authenticationService = {
  getLoginPage,
  redeemToken,
  refreshToken,
  logoutUser
};

function getLoginPage (districtAppId, siteGuid, loginPrompt, fullscreen, app) {
  return getAuthenticationUrl(
    districtAppId,
    siteGuid,
    loginPrompt,
    fullscreen,
    app
  );
}

function getAuthenticationUrl (
  districtAppId,
  siteGuid,
  loginPrompt,
  fullscreen,
  app
) {
  let url = SERVER_REQUEST_HOST + '/authenticate';

  url += getQueryParamsAndRelayState(
    districtAppId,
    siteGuid,
    loginPrompt,
    fullscreen,
    app
  );
  return url;
}

function getQueryParamsAndRelayState (
  districtAppId,
  siteGuid,
  loginPrompt,
  fullscreen,
  app
) {
  const relayState = { fullscreen };

  if (loginPrompt) {
    relayState.loginPrompt = loginPrompt;
  }

  if (app) {
    relayState.app = app;
  }

  let queryParams = '';

  if (!_.isEmpty(districtAppId)) {
    relayState.districtId = districtAppId;
    queryParams = '?appId=' + districtAppId;
    if (!_.isEmpty(siteGuid)) {
      relayState.siteGuid = siteGuid;
      queryParams += '&siteGuid=' + encodeURIComponent(siteGuid);
    }
  }

  queryParams += queryParams
    ? '&loginPrompt=' + loginPrompt
    : '?loginPrompt=' + loginPrompt;
  const relayStateStr = encodeURIComponent(JSON.stringify(relayState));
  queryParams += queryParams
    ? '&relayState=' + relayStateStr
    : '?relayState=' + relayStateStr;

  return queryParams;
}

async function redeemToken (token) {
  const requestOptions = {
    method: 'GET',
    credentials: 'include'
  };

  const url = SERVER_REQUEST_HOST + '/authenticate/redeem?code=' + token;
  const response = await fetch(url, requestOptions);

  if (response.status === 401) {
    window.location.href = '/?error=invalid user';
  }

  if (response.status === 500) {
    window.location.href =
      '/?error=oops, we have a problem, please try again later';
  }

  return response.json();
}

async function refreshTokenRequest () {
  const requestOptions = {
    method: 'GET',
    credentials: 'include'
  };

  const url = SERVER_REQUEST_HOST + '/authenticate/refresh';
  const response = await fetch(url, requestOptions);

  if (response.ok) {
    return response.json();
  }

  return Promise.reject(response);
}

async function refreshToken () {
  const memoizedRefreshToken = mem(refreshTokenRequest, 10000);
  return await memoizedRefreshToken();
}

async function logoutUser () {
  const requestOptions = {
    method: 'GET',
    credentials: 'include'
  };

  const url = SERVER_REQUEST_HOST + '/authenticate/logout';
  await fetch(url, requestOptions);
}
