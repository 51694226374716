const SERVER_REQUEST_HOST = process.env.REACT_APP_SERVER_REQUEST_HOST;
const BOOK_IMAGE_URL = process.env.REACT_APP_BOOK_IMAGE_URL;
const DISTRICT_ID_CLAIM = process.env.REACT_APP_DISTRICT_ID_CLAIM;
const DISTRICT_NAME_CLAIM = process.env.REACT_APP_DISTRICT_NAME_CLAIM;
const APP_ID_CLAIM = process.env.REACT_APP_ID_CLAIM;
const SITE_GUID_CLAIM = process.env.REACT_APP_SITE_GUID_CLAIM;
const SITE_LOCATION_CLAIM = process.env.REACT_APP_SITE_LOCATION_CLAIM;

export {
  SERVER_REQUEST_HOST,
  BOOK_IMAGE_URL,
  DISTRICT_ID_CLAIM,
  DISTRICT_NAME_CLAIM,
  SITE_GUID_CLAIM,
  SITE_LOCATION_CLAIM,
  APP_ID_CLAIM
};
