import { DOWN, EQUAL, NEW, UP } from '../constants/constants';

export const compareRecsService = {
  compareRecommendations
};

function compareRecommendations (matrix) {
  const baseElement = matrix[0];
  const results = [baseElement];

  for (let index = 1; index < matrix.length; index++) {
    const element = matrix[index];

    element.recs = mapElementRecsToCompare(element.recs, baseElement);
    results.push(element);
  }

  return results;
}

function mapElementRecsToCompare (recs, baseElement) {
  return recs.map((recItem, recIndex) => {
    const { foundRecElement, baseIndex } = findBaseRecommendations(
      baseElement,
      recItem
    );

    const tags = {
      scoreTag: '',
      positionTag: '',
      posMoved: ''
    };

    if (foundRecElement) {
      compareScore(recItem.score, foundRecElement.score, tags);
      comparePosition(baseIndex, recIndex, tags);
    } else {
      tags.positionTag = NEW;
      tags.posMoved = NEW;
    }

    return { ...recItem, ...tags };
  });
}

function findBaseRecommendations (baseElement, recItem) {
  let baseIndex = -1;

  const foundRecElement = baseElement.recs.find((baseRecItem, index) => {
    if (recItem.isbn_13 === baseRecItem.isbn_13) {
      baseIndex = index;
      return baseElement;
    }

    return undefined;
  });

  return { foundRecElement, baseIndex };
}

function compareScore (recItemScore, foundRecElementScore, tags) {
  if (recItemScore > foundRecElementScore) {
    tags.scoreTag = UP;
  }

  if (recItemScore < foundRecElementScore) {
    tags.scoreTag = DOWN;
  }
}

function comparePosition (baseIndex, recIndex, tags) {
  const posMoved = baseIndex - recIndex;

  if (posMoved > 0) {
    tags.posMoved = '+' + posMoved;
    tags.positionTag = UP;
  }

  if (posMoved < 0) {
    tags.posMoved = posMoved;
    tags.positionTag = DOWN;
  }

  if (posMoved === 0) {
    tags.posMoved = '-';
    tags.positionTag = EQUAL;
  }
}
