import { client } from '../axios/interceptors';

export const siteService = {
  getSites,
  getSiteStats
};

async function getSites (searchValue) {
  try {
    const response = await client.get('/v2/deals/destiny_district_sites', {
      params: { term: searchValue }
    });
    return response.data.sites.map((site) => {
      return { value: site.site_guid, label: site.site_name, district: site.district_name, fscCustomerNo: site.fsc_customer_no };
    });
  } catch (error) {
    return [];
  }
}

async function getSiteStats (siteGuid, trainingTimestamp) {
  try {
    const query = trainingTimestamp ? `?training_timestamp=${trainingTimestamp}` : '';
    const response = await client.get(`/v2/deals/destiny_district_sites/stats/${siteGuid}${query}`);
    return response.data;
  } catch (error) {
    return [];
  }
}
