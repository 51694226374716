import React from 'react';
import PropTypes from 'prop-types';
import './styles.bisac-item.scss';

export const BisacItem = (props) => {
  const { index, bisac, meter } = props;

  return (
    <div className="bisac-item">
      <span
        className={`pos ${bisac.positionTag} ${
          bisac.posMoved == null ? 'base' : ''
        }`}
      >
        <span className="now">{index + 1}</span>

        {bisac.posMoved && <span className="state">{bisac.posMoved}</span>}
      </span>
      <span>
        <p>{bisac.desc}</p>
      </span>
      <span>{bisac.count.toLocaleString()}</span>
      <span className="meter">
        <span style={{ width: `${meter}%` }}></span> {}
      </span>
    </div>
  );
};

BisacItem.propTypes = {
  index: PropTypes.number,
  bisac: PropTypes.object,
  meter: PropTypes.number
};
