import React from 'react';
import PropTypes from 'prop-types';
import { BisacPanel } from '../../organisms/panel-bisac';

export const CompareBisacList = (props) => {
  const { compareMatrix } = props;

  return (
    <div className="compare-container">
      <div className="compare-area">{getList(compareMatrix)}</div>
    </div>
  );
};

function getList (compareMatrix) {
  return compareMatrix.map((model) => {
    return <BisacPanel key={model.guid} model={model} />;
  });
}

CompareBisacList.propTypes = {
  compareMatrix: PropTypes.array
};
