import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { TabContent } from '../tabContent';
import { authContext } from '../../../context/AuthContext';
import { titleService } from '../../../services/titleService';
import { FLRID_FILTER } from '../../../constants/constants';

import './styles.series.tabs.scss';

export const SeriesTabs = (props) => {
  const { cardItem, tabItem, selectedCardItem, mode } = props;

  const { siteGuid } = useContext(authContext);
  const [selectedTab, setSelectedTab] = useState();
  const [titlesData, setTitlesData] = useState([]);

  useEffect(() => {
    setSelectedTab('0');

    const flrids = titleService.getFlridArray(selectedCardItem.titles);

    titleService.getTitlesByFilter(flrids, FLRID_FILTER, siteGuid).then((response) => {
      setTitlesData(response);
    });
  }, [selectedCardItem]);

  return (
    <Tab.Container
      id="left-tabs-example"
      activeKey={selectedTab}
      onSelect={(key) => setSelectedTab(key)}
      defaultActiveKey={0}
    >
      <div className="tab-body">
        {drawTabPanel(tabItem, selectedCardItem)}
        <Col>
          {titlesData && (
            <TabContent
              mode={mode}
              cardItem={cardItem}
              titles={selectedCardItem.titles}
              titlesData={titlesData}
              selectedTab={selectedTab}
            />
          )}
        </Col>
      </div>
    </Tab.Container>
  );
};

function drawTabPanel (TabItem, selectedCardItem) {
  return (
    <Col className="panel">
      <div className="title">Series</div>
      <Nav variant="pills" className="flex-column ">
        <TabItem
          formats={selectedCardItem.formats}
          setFlrids={selectedCardItem.setflrids}
        />
      </Nav>
    </Col>
  );
}

SeriesTabs.propTypes = {
  mode: PropTypes.string,
  cardItem: PropTypes.func,
  tabItem: PropTypes.func,
  selectedCardItem: PropTypes.object
};
