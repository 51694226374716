const MODELS = 'models';
const ID_TOKEN = 'idToken';
const SITE_GUID = 'siteGuid';
const SITE_NAME = 'siteName';
const DISTRICT_NAME = 'districtName';
const FSC_CUSTOMER_NO = 'fscCustomerNo';
const MENU_OPTION = 'menu-option';
const ACCESS_TOKEN = 'accessToken';
const VIEW_OPTION = 'view-option';
const CODE = 'code';
const ERROR = 'error';
const APP_ID = 'appId';
const CANCEL = 'CANCEL';
const LOGIN = 'login';
const ML_MODELS_OPTION = 'ML models';
const COMPLETE_SERIES_OPTION = 'Complete your Series';
const NEW_SERIES_OPTION = 'New Series to Consider';
const TRENDING_OPTION = 'Trending/Popular Titles to Purchase';
const SIMILAR_TITLES_OPTION = 'Similar titles to purchase';
const RECS_TAB = 'recs';
const PARAMS_TAB = 'params';
const METRICS_TAB = 'metrics';
const BISAC_TAB = 'bisac';
const NEW = 'new';
const UP = 'up';
const DOWN = 'down';
const EQUAL = 'same';
const LIST_VIEW = 'list-view';
const GRID_VIEW = 'grid-view';
const ISBN_FILTER = 'by_isbn';
const FLRID_FILTER = 'by_flrid';
const KEY_FILTER = 'by_key';
const ML_TARGET = 'titles-new';
const COMPLETE_SERIES_TARGET = 'series-complete';
const NEW_SERIES_TARGET = 'series-new';
const SIMILAR_TITLES_TARGET = 'similar-titles';
const OTHERS_ALSO_READ_TARGET = 'others-also-liked-titles';
const COMPLETE_MODE = 'complete';
const NEW_MODE = 'new';
const ALS = 'ALS';
const WIDE_AND_DEEP = 'WideAndDeep';
const POPULARITY = 'popularity';

const GRADE_OPTIONS = [
  { value: 'all', label: '-' },
  { value: '0', label: 'K' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: 'K,1,2,3,4,5,6', label: 'K-6' },
  { value: '6,7,8', label: '6-8' },
  { value: '9,10,11,12', label: '9-12' }
];

const LANGUAGE_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'chi', label: 'Chinese' },
  { value: 'eng', label: 'English' },
  { value: 'fre', label: 'French' },
  { value: 'ger', label: 'German' },
  { value: 'ita', label: 'Italian' },
  { value: 'por', label: 'Portuguese' },
  { value: 'spa', label: 'Spanish' }
];

const PUB_YEAR_OPTIONS = [
  { value: 0, label: '-' },
  { value: 1995, label: '1995' },
  { value: 2000, label: '2000' },
  { value: 2005, label: '2005' },
  { value: 2010, label: '2010' },
  { value: 2015, label: '2015' },
  { value: 2019, label: '2019' },
  { value: 2020, label: '2020' },
  { value: 2021, label: '2021' },
  { value: 2022, label: '2022' },
  { value: 2023, label: '2023' },
  { value: 2024, label: '2024' },
  { value: 2025, label: '2025' },
  { value: 2026, label: '2026' }
];

const REVIEW_COUNT_OPTIONS = [
  { value: 0, label: '-' },
  { value: 1, label: '1+' },
  { value: 2, label: '2+' },
  { value: 3, label: '3+' },
  { value: 4, label: '4+' },
  { value: 5, label: '5+' }
];

const SOURCE_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'als', label: 'ALS' },
  { value: 'new', label: 'New' },
  { value: 'content', label: 'Content' }
];

const TOP_N_OPTIONS = [
  { value: 1, label: '1' },
  { value: 3, label: '3' },
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 75, label: '75' },
  { value: 100, label: '100' }
];

export {
  MODELS,
  ID_TOKEN,
  SITE_GUID,
  SITE_NAME,
  DISTRICT_NAME,
  FSC_CUSTOMER_NO,
  MENU_OPTION,
  ACCESS_TOKEN,
  VIEW_OPTION,
  CODE,
  ERROR,
  APP_ID,
  CANCEL,
  LOGIN,
  ML_MODELS_OPTION,
  COMPLETE_SERIES_OPTION,
  NEW_SERIES_OPTION,
  TRENDING_OPTION,
  SIMILAR_TITLES_OPTION,
  RECS_TAB,
  PARAMS_TAB,
  METRICS_TAB,
  BISAC_TAB,
  NEW,
  UP,
  DOWN,
  EQUAL,
  LIST_VIEW,
  GRID_VIEW,
  ISBN_FILTER,
  FLRID_FILTER,
  KEY_FILTER,
  ML_TARGET,
  COMPLETE_SERIES_TARGET,
  NEW_SERIES_TARGET,
  COMPLETE_MODE,
  NEW_MODE,
  ALS,
  WIDE_AND_DEEP,
  POPULARITY,
  GRADE_OPTIONS,
  LANGUAGE_OPTIONS,
  PUB_YEAR_OPTIONS,
  REVIEW_COUNT_OPTIONS,
  SOURCE_OPTIONS,
  TOP_N_OPTIONS,
  SIMILAR_TITLES_TARGET,
  OTHERS_ALSO_READ_TARGET
};
