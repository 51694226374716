import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { ViewSelect } from '../../atoms/viewSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import './styles.compare-toolbar.scss';

export const CompareToolBar = (props) => {
  const {
    modelOptions,
    modelTypeOptions,
    selectedType,
    onChangeModelType,
    handleAddModel,
    handleCompare,
    handleReset,
    disableCompare,
    disableAdd,
    topNOptions,
    selectedTopN,
    onChangeTopN,
    gradeOptions,
    selectedGrades,
    onChangeGrades,
    languageOptions,
    selectedLanguages,
    onChangeLanguages,
    pubYearOptions,
    selectedFromPubYear,
    onChangeFromPubYear,
    selectedToPubYear,
    onChangeToPubYear,
    reviewCountOptions,
    selectedReviewCount,
    onChangeReviewCount,
    sourceOptions,
    selectedSource,
    onChangeSource
  } = props;

  const [selectedModel, setSelectedModel] = useState(undefined);

  const onChangeModel = (model) => {
    setSelectedModel(model);
  };

  const onAddModel = () => {
    handleAddModel(selectedModel);
    setSelectedModel([]);
  };

  const onCompare = () => {
    handleCompare();
  };

  const onReset = () => {
    handleReset();
  };

  return (
    <Navbar bg="light">
      <Container fluid>
        <Stack direction="horizontal" gap={1}>
          <ViewSelect />
        </Stack>
        <div className="filter-options" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1rem' }}>
          <div className="filter-options" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1rem' }}>
            <div>
              <span>Top N:</span>
              <Select
                value={topNOptions.find(option => option.value === selectedTopN)}
                className="basic-single small"
                classNamePrefix="select"
                options={topNOptions}
                isSearchable={false}
                isClearable={false}
                onChange={onChangeTopN}
              />
            </div>

            <div>
              <span>Grades:</span>
              <Select
                value={gradeOptions.find(option => option.value === selectedGrades[0])}
                className="basic-single small"
                classNamePrefix="select"
                options={gradeOptions}
                isSearchable={false}
                isClearable={false}
                onChange={onChangeGrades}
              />
            </div>

            <div>
              <span>Languages:</span>
              <Select
                value={languageOptions.find(option => option.value === selectedLanguages[0])}
                className="basic-single small"
                classNamePrefix="select"
                options={languageOptions}
                isSearchable={false}
                isClearable={false}
                onChange={onChangeLanguages}
              />
            </div>

            <div>
              <span>From Pub Year:</span>
              <Select
                value={pubYearOptions.find(option => option.value === selectedFromPubYear)}
                className="basic-single small"
                classNamePrefix="select"
                options={pubYearOptions}
                isSearchable={false}
                isClearable={false}
                onChange={onChangeFromPubYear}
              />
            </div>

            <div>
              <span>To Pub Year:</span>
              <Select
                value={pubYearOptions.find(option => option.value === selectedToPubYear)}
                className="basic-single small"
                classNamePrefix="select"
                options={pubYearOptions}
                isSearchable={false}
                isClearable={false}
                onChange={onChangeToPubYear}
              />
            </div>

            <div>
              <span>Review Count:</span>
              <Select
                value={reviewCountOptions.find(option => option.value === selectedReviewCount)}
                className="basic-single small"
                classNamePrefix="select"
                options={reviewCountOptions}
                isSearchable={false}
                isClearable={false}
                onChange={onChangeReviewCount}
              />
            </div>

            <div>
              <span>Source:</span>
              <Select
                value={sourceOptions.find(option => option.value === selectedSource)}
                className="basic-single small"
                classNamePrefix="select"
                options={sourceOptions}
                isSearchable={false}
                isClearable={false}
                onChange={onChangeSource}
              />
            </div>
          </div>

          <div className="filter-options" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1rem' }}>
            <div>
              <span>Model type:</span>
              <Select
                value={selectedType}
                className="basic-single"
                classNamePrefix="select"
                options={modelTypeOptions}
                isSearchable={true}
                isClearable={false}
                onChange={onChangeModelType}
              />
            </div>

            <div>
              <span>Add Model:</span>
              <Select
                value={selectedModel}
                className="basic-single"
                classNamePrefix="select"
                options={modelOptions}
                isDisabled={selectedType ? false || disableAdd : true}
                isSearchable={true}
                isClearable={false}
                onChange={onChangeModel}
              />
              <Button
                variant="outline-secondary"
                onClick={onAddModel}
                disabled={disableAdd}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>

            <div className="vr" />
            <Button
              variant="outline-primary"
              onClick={onCompare}
              disabled={disableCompare}
            >
              Compare
            </Button>
            <Button variant="outline-secondary" onClick={onReset}>
              Clear
            </Button>
          </div>
        </div>
      </Container>
    </Navbar>
  );
};

CompareToolBar.propTypes = {
  modelOptions: PropTypes.array,
  modelTypeOptions: PropTypes.array,
  selectedType: PropTypes.object,
  onChangeModelType: PropTypes.func,
  handleAddModel: PropTypes.func,
  handleCompare: PropTypes.func,
  handleReset: PropTypes.func,
  disableCompare: PropTypes.bool,
  disableAdd: PropTypes.bool,
  topNOptions: PropTypes.array,
  selectedTopN: PropTypes.number,
  onChangeTopN: PropTypes.func,
  gradeOptions: PropTypes.array,
  selectedGrades: PropTypes.array,
  onChangeGrades: PropTypes.func,
  languageOptions: PropTypes.array,
  selectedLanguages: PropTypes.array,
  onChangeLanguages: PropTypes.func,
  pubYearOptions: PropTypes.array,
  selectedFromPubYear: PropTypes.number,
  onChangeFromPubYear: PropTypes.func,
  selectedToPubYear: PropTypes.number,
  onChangeToPubYear: PropTypes.func,
  reviewCountOptions: PropTypes.array,
  selectedReviewCount: PropTypes.string,
  onChangeReviewCount: PropTypes.func,
  sourceOptions: PropTypes.array,
  selectedSource: PropTypes.string,
  onChangeSource: PropTypes.func
};
