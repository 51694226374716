import { client } from '../axios/interceptors';

export const trendingService = {
  getTrendingRecs
};

async function getTrendingRecs (selectedModel, selectedSite) {
  try {
    const uri = getRecommendationUri(selectedModel, selectedSite);
    const response = await client.get(uri);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

function getRecommendationUri (selectedModel, selectedSite) {
  return `/v2/apps/12345/sites/${selectedSite}/recs/titles/popular?model_guid=${selectedModel.guid}`;
}
