import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { decodeToken } from '../utils/helpers';
import { SITE_GUID_CLAIM, SITE_LOCATION_CLAIM, DISTRICT_NAME_CLAIM, APP_ID_CLAIM } from '../constants/enviroment';
import {
  ID_TOKEN,
  ACCESS_TOKEN,
  SITE_GUID,
  SITE_NAME,
  DISTRICT_NAME,
  FSC_CUSTOMER_NO,
  MENU_OPTION,
  ML_MODELS_OPTION,
  MODELS,
  VIEW_OPTION,
  APP_ID
} from '../constants/constants';

export const authContext = createContext();

export const ProvideAuth = ({ children }) => {
  const [isAuth, setIsAuth] = useState(() => {
    return !!window.localStorage.getItem(ID_TOKEN);
  });

  const [siteGuid, setSiteGuid] = useState(() => {
    return window.localStorage.getItem(SITE_GUID);
  });

  const [appId, setAppId] = useState(() => {
    return window.localStorage.getItem(APP_ID);
  });

  const [siteName, setSiteName] = useState(() => {
    return window.localStorage.getItem(SITE_NAME);
  });

  const [districtName, setDistrictName] = useState(() => {
    return window.localStorage.getItem(DISTRICT_NAME);
  });

  const [fscCustomerNo, setFscCustomerNo] = useState(() => {
    return window.localStorage.getItem(FSC_CUSTOMER_NO);
  });

  const value = {
    isAuth,
    siteGuid,
    siteName,
    districtName,
    fscCustomerNo,
    appId,
    activateAuth: (tokens) => {
      setIsAuth(true);
      window.localStorage.setItem(ID_TOKEN, tokens.idToken);
      window.localStorage.setItem(ACCESS_TOKEN, tokens.accessToken);

      const decodedToken = decodeToken(tokens.idToken);
      const siteGuidClaim = decodedToken[SITE_GUID_CLAIM];
      const siteNameClaim = decodedToken[SITE_LOCATION_CLAIM];
      const districtNameClaim = decodedToken[DISTRICT_NAME_CLAIM];
      const appIdClaim = decodedToken[APP_ID_CLAIM];
      setSiteGuid(siteGuidClaim);
      setSiteName(siteNameClaim);
      setDistrictName(districtNameClaim);
      setAppId(appIdClaim);

      window.localStorage.setItem(SITE_GUID, siteGuidClaim);
      window.localStorage.setItem(SITE_NAME, siteNameClaim);
      window.localStorage.setItem(DISTRICT_NAME, districtNameClaim);
      window.localStorage.setItem(MENU_OPTION, ML_MODELS_OPTION);
      window.localStorage.setItem(APP_ID, appIdClaim);
    },

    removeAuth: () => {
      setIsAuth(false);
      window.localStorage.removeItem(ID_TOKEN);
      window.localStorage.removeItem(ACCESS_TOKEN);
      window.localStorage.removeItem(SITE_GUID);
      window.localStorage.removeItem(SITE_NAME);
      window.localStorage.removeItem(DISTRICT_NAME);
      window.localStorage.removeItem(FSC_CUSTOMER_NO);
      window.localStorage.removeItem(MENU_OPTION);
      window.localStorage.removeItem(MODELS);
      window.localStorage.removeItem(VIEW_OPTION);
      window.localStorage.removeItem(APP_ID);
    },
    setLocation: (siteGuid, siteName, districtName, fscCustomerNo) => {
      setSiteGuid(siteGuid);
      setDistrictName(districtName);
      setFscCustomerNo(fscCustomerNo);
      window.localStorage.setItem(SITE_GUID, siteGuid);
      window.localStorage.setItem(SITE_NAME, siteName);
      window.localStorage.setItem(DISTRICT_NAME, districtName);
      window.localStorage.setItem(FSC_CUSTOMER_NO, fscCustomerNo);
    },
    updateTokens (accessToken, idToken) {
      window.localStorage.setItem(ID_TOKEN, idToken);
      window.localStorage.setItem(ACCESS_TOKEN, accessToken);
    }
  };

  return <authContext.Provider value={value}>{children}</authContext.Provider>;
};

ProvideAuth.propTypes = {
  children: PropTypes.element
};
