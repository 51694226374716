import { client } from '../axios/interceptors';
import { FLRID_FILTER, ISBN_FILTER, KEY_FILTER } from '../constants/constants';

export const titleService = {
  getTitlesByFilter,
  getFlridArray,
  getIsbnArray,
  findTitleByIsbn,
  findSerieTitleByFlrid,
  findSerieTitleByFlrid2,
  findSerieTitleByIsbn13orKey,
  getTitlesByTerm
};

async function getTitlesByFilter (array, filterType, siteGuid) {
  try {
    const uri = getUri(array, filterType, siteGuid);
    const response = await client.get(uri);
    return response.data.titles;
  } catch (error) {
    return [];
  }
}

function getUri (array, filterType, siteGuid) {
  const isbnsQP = getParams(array, filterType);
  return `/v2/apps/12345/sites/${siteGuid}/recs/titles/title/${filterType}?${isbnsQP}`;
}

function getParams (array, filterType) {
  let query_params = '';
  const name_param = getNameParam(filterType);

  array.forEach((isbn, i) => {
    if (i > 0) {
      query_params += '&';
    }
    query_params += `${name_param}${isbn}`;
  });

  return query_params;
}

function getNameParam (filterType) {
  switch (filterType) {
    case FLRID_FILTER:
      return 'flrid=';
    case ISBN_FILTER:
      return 'isbn=';
    case KEY_FILTER:
      return 'key[]=';
    default:
      return '';
  }
}

function getFlridArray (titles) {
  let flrids = [];

  if (titles) {
    titles.forEach((title) => {
      if (title.flrids) {
        flrids = [...flrids, ...title.flrids];
      } else if (title.flrid) {
        flrids.push(title.flrid);
      }
    });
  }

  // Remove empty items from the array
  flrids = flrids.filter(flrid => flrid !== '');

  return flrids;
}

function getIsbnArray (recs) {
  if (recs) {
    return recs.map((rec) => {
      return rec.isbn_13;
    });
  } else {
    return [];
  }
}

function findTitleByIsbn (isbn, titlesData) {
  return titlesData.find((title) => {
    return title.isbn_13.toString() === isbn.toString();
  });
}

function findSerieTitleByFlrid2 (flrid, titlesData) {
  return titlesData.find((title) => {
    return title.flrid.toString() === flrid.toString();
  });
}

function findSerieTitleByIsbn13orKey (isbn_13, titlesData) {
  return titlesData.find((title) => {
    return title.key.toString() === isbn_13.toString() || title.isbn_13.toString() === isbn_13.toString();
  });
}

function findSerieTitleByFlrid (flrids, titlesData, selectedTab) {
  return titlesData.find((title) => {
    if (flrids[selectedTab]) {
      return title.flrid.toString() === flrids[selectedTab].toString();
    }

    return undefined;
  });
}

async function getTitlesByTerm (searchValue, vectorType, siteGuid) {
  try {
    const response = await client.get(`/v2/apps/12345/sites/${siteGuid}/recs/titles`, {
      params: { term: searchValue, type: vectorType }
    });
    return response.data.recs.map((title) => {
      return { value: title.isbn_13, label: title.title };
    });
  } catch (error) {
    return [];
  }
}
