import { DOWN, UP } from '../constants/constants';

export const compareMetricsService = {
  compareItem,
  formatItem
};

function compareItem (currentItem, previousItem, keySet) {
  let tag;
  keySet.forEach((key) => {
    tag = '';
    if (previousItem !== null) {
      if (currentItem[key] && previousItem[key]) {
        if (currentItem[key].value > previousItem[key].value) {
          tag = UP;
        }

        if (currentItem[key].value < previousItem[key].value) {
          tag = DOWN;
        }
      }
    }

    currentItem[key] = { ...currentItem[key], tag };
  });

  return currentItem;
}

function formatItem (item, keySet) {
  if (!item) {
    item = {};
  }

  keySet.forEach((key) => {
    if (item[key]) {
      const value = !isNaN(item[key])
        ? Number(item[key]).toFixed(3)
        : item[key];
      item[key] = {
        value
      };
    } else {
      item[key] = { value: '-' };
    }
  });

  return item;
}
