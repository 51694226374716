import React from 'react';
import PropTypes from 'prop-types';
import { ComparePanel } from '../../organisms/panel-compare';

export const CompareRecsList = (props) => {
  const { compareMatrix, titlesData } = props;

  return (
    <div className="compare-container">
      <div className="compare-area">{getList(compareMatrix, titlesData)}</div>
    </div>
  );
};

function getList (compareMatrix, titlesData) {
  // console.log('compareMatrix', compareMatrix);
  // console.log('titlesData', titlesData);

  return compareMatrix.map((model) => {
    return (
      <ComparePanel key={model.guid} model={model} titlesData={titlesData} />
    );
  });
}

CompareRecsList.propTypes = {
  compareMatrix: PropTypes.array,
  titlesData: PropTypes.array
};
