import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Stack from 'react-bootstrap/Stack';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { LogoutButton } from '../../atoms/logoutButton';
import { SitesSelect } from '../../atoms/sitesSelect';
import {
  MENU_OPTION,
  ML_MODELS_OPTION,
  COMPLETE_SERIES_OPTION,
  NEW_SERIES_OPTION,
  TRENDING_OPTION,
  VIEW_OPTION,
  SIMILAR_TITLES_OPTION
} from '../../../constants/constants';

import './styles.navbar.scss';

export const NavBar = (props) => {
  const location = useLocation();
  const [navDropdownOption, setNavDropdownOption] = useState(() => {
    return window.localStorage.getItem(MENU_OPTION);
  });

  const onClickOption = (option) => {
    setNavDropdownOption(option);
    window.localStorage.setItem(MENU_OPTION, option);

    if (option === ML_MODELS_OPTION) {
      window.localStorage.setItem(VIEW_OPTION, 'dashboard');
    }
  };

  return (
    <Navbar className="main-navbar" expand="lg">
      <Container fluid>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" activeKey={location.pathname}>
            <NavDropdown
              title={
                <span className="text-white">
                  <FontAwesomeIcon className="text-white" icon={faBars} /> Menu
                </span>
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                as={Link}
                to="/dashboard"
                onClick={() => {
                  onClickOption(ML_MODELS_OPTION);
                }}
              >
                {ML_MODELS_OPTION}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/complete-series"
                onClick={() => {
                  onClickOption(COMPLETE_SERIES_OPTION);
                }}
              >
                {COMPLETE_SERIES_OPTION}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/new-series"
                onClick={() => {
                  onClickOption(NEW_SERIES_OPTION);
                }}
              >
                {NEW_SERIES_OPTION}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/trending-titles"
                onClick={() => {
                  onClickOption(TRENDING_OPTION);
                }}
              >
                {TRENDING_OPTION}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/similar-titles"
                onClick={() => {
                  onClickOption(SIMILAR_TITLES_OPTION);
                }}
              >
                {SIMILAR_TITLES_OPTION}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={LogoutButton}></NavDropdown.Item>
            </NavDropdown>
            <div className="vr" />
            <Navbar.Brand>{navDropdownOption}</Navbar.Brand>
          </Nav>
          <Nav>
            <Stack direction="horizontal" gap={3}>
              <Form.Group>
                <SitesSelect />
              </Form.Group>
            </Stack>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
