import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { RowSeries } from '../row-series';
import './styles.series.panel.scss';

export const SeriesPanel = (props) => {
  const { cardItem, tabItem, series, errorMessage, mode } = props;

  return (
    <Fragment>
      {errorMessage &&
        (<div className='error-container'>
              <p className='error-msg'>{errorMessage}</p>
            </div>
        )}

      {series.length > 0 &&
        <div className="main">
          <div className='series-panel-container'>
          <RowSeries
              mode={mode}
              panelRow={series}
              cardItem={cardItem}
              tabItem={tabItem}
            />
          </div>
        </div>
        }
    </Fragment>
  );
};

SeriesPanel.propTypes = {
  mode: PropTypes.string,
  cardItem: PropTypes.func,
  tabItem: PropTypes.func,
  series: PropTypes.array,
  errorMessage: PropTypes.string
};
