import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { modelService } from '../../../services/modelService';

import './styles.dashboard-toolbar.scss';

export const SeriesToolBar = (props) => {
  const {
    gradeOptions,
    selectedGrades,
    onChangeGrades,
    languageOptions,
    selectedLanguages,
    onChangeLanguages,
    pubYearOptions,
    selectedFromPubYear,
    onChangeFromPubYear,
    selectedToPubYear,
    onChangeToPubYear,
    modelOptions,
    selectedModel,
    onChangeModel,
    modelTypeOptions,
    selectedType,
    onChangeModelType
  } = props;

  const modelOptionValue = selectedModel
    ? modelService.mapModelToOption(selectedModel)
    : undefined;

  return (
    <Navbar bg="light">
      <Container fluid>
        <div className="filter-options" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1rem' }}>
          <div>
            <span>Grades:</span>
            <Select
              value={gradeOptions.find(option => option.value === selectedGrades[0])}
              className="basic-single small"
              classNamePrefix="select"
              options={gradeOptions}
              isSearchable={false}
              isClearable={false}
              onChange={onChangeGrades}
            />
          </div>

          <div>
            <span>Languages:</span>
            <Select
              value={languageOptions.find(option => option.value === selectedLanguages[0])}
              className="basic-single small"
              classNamePrefix="select"
              options={languageOptions}
              isSearchable={false}
              isClearable={false}
              onChange={onChangeLanguages}
            />
          </div>

          <div>
            <span>From Pub Year:</span>
            <Select
              value={pubYearOptions.find(option => option.value === selectedFromPubYear)}
              className="basic-single small"
              classNamePrefix="select"
              options={pubYearOptions}
              isSearchable={false}
              isClearable={false}
              onChange={onChangeFromPubYear}
            />
          </div>

          <div>
            <span>To Pub Year:</span>
            <Select
              value={pubYearOptions.find(option => option.value === selectedToPubYear)}
              className="basic-single small"
              classNamePrefix="select"
              options={pubYearOptions}
              isSearchable={false}
              isClearable={false}
              onChange={onChangeToPubYear}
            />
          </div>

          <div>
            <span>Model type:</span>
            <Select
              value={selectedType}
              className="basic-single"
              classNamePrefix="select"
              options={modelTypeOptions}
              isSearchable={true}
              isClearable={false}
              onChange={onChangeModelType}
            />
          </div>

          <div>
            <span>Model:</span>
            <Select
              value={modelOptionValue}
              className="basic-single"
              classNamePrefix="select"
              options={modelService.getModelOptions(modelOptions)}
              isSearchable={true}
              isClearable={false}
              onChange={onChangeModel}
            />
          </div>
        </div>
      </Container>
    </Navbar>
  );
};

SeriesToolBar.propTypes = {
  modelOptions: PropTypes.array,
  selectedModel: PropTypes.object,
  onChangeModel: PropTypes.func,
  modelTypeOptions: PropTypes.array,
  selectedType: PropTypes.object,
  onChangeModelType: PropTypes.func,
  gradeOptions: PropTypes.array,
  selectedGrades: PropTypes.array,
  onChangeGrades: PropTypes.func,
  languageOptions: PropTypes.array,
  selectedLanguages: PropTypes.array,
  onChangeLanguages: PropTypes.func,
  pubYearOptions: PropTypes.array,
  selectedFromPubYear: PropTypes.number,
  onChangeFromPubYear: PropTypes.func,
  selectedToPubYear: PropTypes.number,
  onChangeToPubYear: PropTypes.func
};
