import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { BOOK_IMAGE_URL } from '../../../constants/enviroment';
import noTitleThumbnail from '../../../../../public/images/titleNotFound.png';

import './styles.panel-title.scss';

export const TitlePanel = (props) => {
  const { title } = props;

  let titleStatus = 'unknown';
  if (title) {
    switch (title.status) {
      case 0:
        titleStatus = 'Deleted';
        break;
      case 1:
        titleStatus = 'Pre-Sales';
        break;
      case 2:
        titleStatus = 'Non-sellable';
        break;
      case 3:
        titleStatus = 'Sellable';
        break;
    }
  }

  return (
    <div className="panel title">
      <div className="curated">
        <div className="thumbnail">
          {
            <img
              src={`${BOOK_IMAGE_URL}${title.isbn_13}`}
              onLoad={(a) => {
                if (a.target.naturalWidth === 1) {
                  a.target.src = noTitleThumbnail;
                }
              }}
              onError={(a) => {
                a.target.src = noTitleThumbnail;
              }}
            />
          }
        </div>
        <div className="details">
          <div className="first-row">
            <span className="fil-container">
              <span className="fil">{title.follett_interest_level}</span>
            </span>
            <span className="format">{title.format}</span>
            <span className="year">{title.publication_year}</span>
          </div>
          <div className="names-values">
            <span>
              <span>FLRID:</span>
              <span>{title.flrid || '-'}</span>
            </span>
            <span>
              <span>ISBN-13:</span>
              <span>{title.isbn_13 || '-'}</span>
            </span>
            <span>
              <span>ISBN-10:</span>
              <span>{title.isbn_10 || '-'}</span>
            </span>
            <span>
              <span>FB-ISBN:</span>
              <span>{title.fbisbn || '-'}</span>
            </span>
            <span>
              <span>Author:</span>
              <span>{title.author || '-'}</span>
            </span>
            <span>
              <span>Illustrator:</span>
              <span>{title.illustrator || '-'}</span>
            </span>
          </div>
          <div className={`status s${title.status}`}>
            <span>{titleStatus}</span>
          </div>
        </div>
      </div>
      <div className="raw">
        {title &&
          Object.entries(title).map(([key, value], index) => {
            return (
              <Fragment key={key}>
                <>
                  <span>{key}</span>
                  <span>{JSON.stringify(value)}</span>
                </>
              </Fragment>
            );
          })}
      </div>
    </div>
  );
};

TitlePanel.propTypes = {
  title: PropTypes.object
};
