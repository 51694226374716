import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RecommendationList } from '../../molecules/rec-list/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { faBars, faGrip } from '@fortawesome/free-solid-svg-icons';

import Badge from 'react-bootstrap/Badge';
import { GRID_VIEW, LIST_VIEW } from '../../../constants/constants';

import './styles.panel-recs.scss';

export const RecommendationsPanel = (props) => {
  const { recommendations, titlesData, isDefaultRecs, errorMessage, useTitleKeyCheck } = props;
  const [selectedView, setView] = useState(GRID_VIEW);

  const onToggleView = () => {
    if (selectedView === GRID_VIEW) {
      setView(LIST_VIEW);
    } else {
      setView(GRID_VIEW);
    }
  };

  return (
    <div className="panel recommendations">
      <div className="title">
        Recommendations &nbsp; &nbsp;
        <Button
          variant="light"
          id="recs-view-icon-container"
          onClick={onToggleView}
        >
          {selectedView === GRID_VIEW
            ? (
            <FontAwesomeIcon icon={faGrip} />
              )
            : (
            <FontAwesomeIcon icon={faBars} />
              )}
        </Button>
      </div>
      {isDefaultRecs && <Badge bg="secondary">Default recs</Badge>}
      <div className="content">
        {
          errorMessage
            ? (<div className='error-container'>
                <p className='error-msg'>{errorMessage}</p>
              </div>)
            : (<RecommendationList
                recommendations={recommendations}
                titlesData={titlesData}
                viewType={selectedView}
                useTitleKeyCheck={useTitleKeyCheck}
              />)
        }

      </div>
    </div>
  );
};

RecommendationsPanel.propTypes = {
  recommendations: PropTypes.array,
  titlesData: PropTypes.array,
  isDefaultRecs: PropTypes.bool,
  useTitleKeyCheck: PropTypes.bool,
  errorMessage: PropTypes.string
};
