import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import { BOOK_IMAGE_URL } from '../../../constants/enviroment';
import noTitleThumbnail from '../../../../../public/images/titleNotFound.png';

import './styles.carousel-card-item.scss';

export const CarouselCardCompleteSeries = (props) => {
  const { item, titleData, selectedTab, handleShow } = props;

  return (
    <Card className={item.in_catalogs[selectedTab] ? 'in-catalog' : ''}>
      {item.in_catalogs[selectedTab] && <Badge bg="danger">in catalog</Badge>}
      {titleData
        ? (
        <Fragment>
          <Card.Img
            className="carousel-card-img"
            src={`${BOOK_IMAGE_URL}${titleData.isbn_13}`}
            onClick={() => handleShow(titleData)}
            onLoad={(a) => {
              if (a.target.naturalWidth === 1) {
                a.target.src = noTitleThumbnail;
              }
            }}
            onError={(a) => {
              a.target.src = noTitleThumbnail;
            }}
          />

          <Card.Body>
            <Card.Title className="text-truncate">{titleData.title}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted text-truncate">
              {titleData.author.join()}
            </Card.Subtitle>
            <hr />
            <Row>
              <Col className="grid-display">
                <span>{`Flrid: ${item.flrids[selectedTab]}`}</span>
                <span>{`Grades: ${titleData.follett_interest_level}`}</span>
                <span>{`Popularity: ${titleData.popularity_score.toFixed(
                  2
                )}`}</span>
                <span>{`Book: ${item.seq}`}</span>
                <span>{`Checkout: ${
                  item.checkout_count ? item.checkout_count : '-'
                }`}</span>
              </Col>
            </Row>
          </Card.Body>
        </Fragment>
          )
        : (
        <Fragment>
          <Card.Img className="carousel-card-img" src={noTitleThumbnail} />
          <Card.Body>
            <Card.Title className="text-truncate text-center">
              Data not available
            </Card.Title>
          </Card.Body>
        </Fragment>
          )}
    </Card>
  );
};

CarouselCardCompleteSeries.propTypes = {
  item: PropTypes.object,
  titleData: PropTypes.object,
  selectedTab: PropTypes.string,
  handleShow: PropTypes.func
};
