import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as dayjs from 'dayjs';
import { client } from '../axios/interceptors';
dayjs.extend(localizedFormat);

export const modelService = {
  getModels,
  getModelOptions,
  mapModelToOption,
  getModelName,
  getStatisticsKeysFromModel
};

async function getModels (target = null) {
  try {
    let queryParams = '';
    if (target) {
      const params = new URLSearchParams();

      target.forEach((t) => params.append('target', t));

      queryParams = params.toString();
    }

    const url = target ? `/v2/recs/recommender_models?${queryParams}` : '/v2/recs/recommender_models/';
    const response = await client.get(url);
    return response.data.recommender_models;
  } catch (error) {
    return [];
  }
}

function getModelOptions (models) {
  return models.map((model) => {
    return mapModelToOption(model);
  });
}

function mapModelToOption (model) {
  const modelInfo = getModelName(model);
  return { value: model.guid, label: modelInfo };
}

function getModelName (model) {
  return model.created_at
    ? `${model.name} (${dayjs(model.created_at).format('LL')})`
    : model.name;
}

function getStatisticsKeysFromModel (model, obj) {
  const tempParamsKeys = model.params ? Object.keys(model.params) : [];
  const tempMetricsKeys = model.metrics ? Object.keys(model.metrics) : [];

  obj.paramsKeys = [...obj.paramsKeys, ...tempParamsKeys];
  obj.metricsKeys = [...obj.metricsKeys, ...tempMetricsKeys];
}
