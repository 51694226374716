import { client } from '../axios/interceptors';

export const seriesService = {
  getSeries,
  countInCatalog,
  orderTitles
};

async function getSeries (mode, selectedSite, selectedModel, grades, languages, fromPubYear, toPubYear) {
  try {
    const gradesParam = grades.includes('all') ? '' : `&grades=${grades.join(',')}`;
    const languagesParam = languages.includes('all') ? '' : `&langs=${languages.join(',')}`;
    const fromPubYearParam = fromPubYear === 0 ? '' : `&from_pub_year=${fromPubYear}`;
    const toPubYearParam = toPubYear === 0 ? '' : `&to_pub_year=${toPubYear}`;
    const uri = `/v2/apps/12345/sites/${selectedSite}/recs/series/${mode}?raw=true&model_guid=${selectedModel.guid}${gradesParam}${languagesParam}${fromPubYearParam}${toPubYearParam}`;
    const response = await client.get(uri);

    return response.data.recs;
  } catch (error) {
    console.error(error);
    return [];
  }
}

function countInCatalog (titles, selectedTab) {
  return titles.reduce((accumulated, currentValue) => {
    if (currentValue.in_catalogs[selectedTab]) {
      return accumulated + 1;
    }

    return accumulated;
  }, 0);
}

function orderTitles (titles) {
  return titles.sort((a, b) => (a.seq > b.seq ? 1 : a.seq < b.seq ? -1 : 0));
}
