import React from 'react';
import PropTypes from 'prop-types';
import { CompareList } from '../../molecules/compare-list';

import './styles.panel-checkout.scss';

export const ComparePanel = (props) => {
  const { model, titlesData } = props;

  return (
    <div className="panel compare">
      <div className="content">
        <CompareList model={model} titlesData={titlesData} />
      </div>
    </div>
  );
};

ComparePanel.propTypes = {
  model: PropTypes.object,
  titlesData: PropTypes.array
};
