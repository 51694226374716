import { useEffect, useState } from 'react';
import { modelService } from '../services/modelService';

export const useModels = () => {
  const [models, setModels] = useState([]);
  const [modelTypeOptions, setModelTypeOptions] = useState([]);
  const [modelsBasedOnType, setModelsBasedOnType] = useState([]);

  const updateModelTypeOptions = (target, excludeType = []) => {
    let options = [];
    if (models) {
      models.forEach((model) => {
        if (!excludeType.includes(model.type) && model.target === target) {
          options.push(model.type);
        }
      });
    }

    options = Array.from(new Set(options));

    const temp = options.map((item) => {
      return { value: item, label: item };
    });

    setModelTypeOptions(temp);
  };

  const updteModelsBasedOnType = (target, type) => {
    const modelsBasedOnType = models
      .filter((element) => element.type === type && element.target === target)
      .sort((a, b) =>
        a.created_at < b.created_at ? 1 : a.created_at > b.created_at ? -1 : 0
      );

    setModelsBasedOnType(modelsBasedOnType);
  };

  const getModelInfoToCompare = (modelGuid) => {
    return models.find((model) => model.guid === modelGuid);
  };

  useEffect(() => {
    modelService
      .getModels()
      .then((data) => {
        setModels(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return {
    models,
    modelTypeOptions,
    modelsBasedOnType,
    updateModelTypeOptions,
    updteModelsBasedOnType,
    getModelInfoToCompare
  };
};
