import React, { Fragment, useContext, useState, useEffect } from 'react';
import { authContext } from '../../../context/AuthContext';
import { SeriesPanel } from '../../atoms/series-panel';
import { Spinner } from '../../atoms/spinner';
import { SeriesToolBar } from '../../molecules/series-toolbar';
import { CarouselCardCompleteSeries } from '../../atoms/carousel-card-complete-series';
import { TabItemCompleteSeries } from '../../atoms/tab-Item-complete-series';
import { seriesService } from '../../../services/seriesService';
import { useModels } from '../../../hooks/useModels';
import {
  COMPLETE_MODE,
  COMPLETE_SERIES_TARGET,
  GRADE_OPTIONS,
  LANGUAGE_OPTIONS,
  PUB_YEAR_OPTIONS
} from '../../../constants/constants';

export const CompleteSeries = () => {
  const {
    models,
    modelTypeOptions,
    modelsBasedOnType,
    updateModelTypeOptions,
    updteModelsBasedOnType
  } = useModels();
  const { siteGuid } = useContext(authContext);
  const [series, setSeries] = useState([]);
  const [model, setModel] = useState();
  const [modelType, setModelType] = useState();
  const [grades, setGrades] = useState(['all']);
  const [languages, setLanguages] = useState(['all']);
  const [fromPubYear, setFromPubYear] = useState(0);
  const [toPubYear, setToPubYear] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const updateGrades = (selectedOption) => {
    setGrades([selectedOption.value]);
  };

  const updateLanguages = (selectedOption) => {
    setLanguages([selectedOption.value]);
  };

  const updateFromPubYear = (selectedOption) => {
    setFromPubYear(selectedOption.value);
  };

  const updateToPubYear = (selectedOption) => {
    setToPubYear(selectedOption.value);
  };

  const updateModel = (option) => {
    const newModel = models.find((model) => {
      return model.guid === option.value;
    });

    setModel(newModel);
  };

  const updateTypeModel = (option) => {
    setModelType(option);
  };

  useEffect(
    function () {
      updateModelTypeOptions(COMPLETE_SERIES_TARGET);
    },
    [models]
  );

  useEffect(
    function () {
      setModelType(modelTypeOptions[0]);
    },
    [modelTypeOptions]
  );

  useEffect(
    function () {
      const defaultType = modelType ? modelType.value : undefined;
      updteModelsBasedOnType(COMPLETE_SERIES_TARGET, defaultType);
    },
    [modelType]
  );

  useEffect(
    function () {
      setModel(modelsBasedOnType[0]);
    },
    [modelsBasedOnType]
  );

  useEffect(
    function () {
      if (model) {
        setLoading(true);
        seriesService
          .getSeries(COMPLETE_MODE, siteGuid, model, grades, languages, fromPubYear, toPubYear)
          .then((series) => {
            setErrorMessage();
            setSeries(series);
          })
          .catch((error) => {
            console.error(error);
            setSeries([]);
            setErrorMessage('Could not fetch series for this site, Please try another model or site');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [siteGuid, model, grades, languages, fromPubYear, toPubYear]
  );

  return (
    <Fragment>
      {loading && <Spinner></Spinner>}
      <SeriesToolBar
        modelOptions={modelsBasedOnType}
        selectedModel={model}
        onChangeModel={updateModel}
        modelTypeOptions={modelTypeOptions}
        selectedType={modelType}
        onChangeModelType={updateTypeModel}
        gradeOptions={GRADE_OPTIONS}
        selectedGrades={grades}
        onChangeGrades={updateGrades}
        languageOptions={LANGUAGE_OPTIONS}
        selectedLanguages={languages}
        onChangeLanguages={updateLanguages}
        pubYearOptions={PUB_YEAR_OPTIONS}
        selectedFromPubYear={fromPubYear}
        onChangeFromPubYear={updateFromPubYear}
        selectedToPubYear={toPubYear}
        onChangeToPubYear={updateToPubYear}
      />
      <div className="content">
        <SeriesPanel
          mode={COMPLETE_MODE}
          series={series}
          cardItem={CarouselCardCompleteSeries}
          tabItem={TabItemCompleteSeries}
          errorMessage={errorMessage}
        />
      </div>
    </Fragment>
  );
};
