import { DOWN, EQUAL, NEW, UP } from '../constants/constants';
import { titleService } from './titleService';

export const compareBisacsService = {
  compareBisacs
};

function mapBisacsToCompare (matrix, titlesData) {
  matrix.forEach((model) => {
    const bisacs = getBisacsFromTitlesRecs(model.recs, titlesData);
    const bisacCounts = countAndSortBisacs(bisacs);
    model.bisacs = [...bisacCounts];
  });
}

function getBisacsFromTitlesRecs (recs, titlesData) {
  const recFlrids = titleService.getFlridArray(recs);

  const recTitles = titlesData.filter((title) =>
    recFlrids.includes(title.flrid)
  );

  return recTitles
    .map((title) => {
      return title.bisacs.reduce(function (filtered, bisac) {
        if (bisac.description !== 'JUVENILE FICTION / General') {
          let desc = bisac.description.replace(
            /^JUVENILE NONFICTION \//i,
            'JNF /'
          );
          desc = desc.replace(/^JUVENILE FICTION \//i, 'JF /');
          desc = desc.replace(/^YOUNG ADULT FICTION \//i, 'YAF /');
          filtered.push({ code: bisac.bisac, desc });
        }
        return filtered;
      }, []);
    })
    .flat(1);
}

function countAndSortBisacs (bisacs) {
  const bisacCounts = bisacs.reduce(function (arr, item) {
    let found = false;

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].code === item.code) {
        found = true;
        arr[i].count++;
      }
    }

    if (!found) {
      item.count = 1;
      arr.push(item);
    }

    return arr;
  }, []);

  bisacCounts.sort((a, b) => {
    // First, compare by 'count'
    if (a.count < b.count) return 1;
    if (a.count > b.count) return -1;

    // If 'count' is equal, compare by 'desc'
    if (a.desc < b.desc) return -1;
    if (a.desc > b.desc) return 1;

    return 0; // Objects are equal
  });

  return [...bisacCounts];
}

function compareBisacs (matrix, titlesData) {
  mapBisacsToCompare(matrix, titlesData);
  compare(matrix);
}

function compare (matrix) {
  const baseBisacs = matrix[0].bisacs;

  for (let index = 1; index < matrix.length; index++) {
    let bisacs = matrix[index].bisacs;
    bisacs = bisacs.forEach((bisac, index) => {
      const { foundBaseBisac, baseIndex } = findBaseBisac(baseBisacs, bisac);
      if (foundBaseBisac) {
        bisac.posMoved = baseIndex - index;
        comparePosition(bisac);
      } else {
        bisac.positionTag = NEW;
        bisac.posMoved = NEW;
      }
    });
  }
}

function comparePosition (bisac) {
  if (bisac.posMoved > 0) {
    bisac.posMoved = '+' + bisac.posMoved;
    bisac.positionTag = UP;
  }

  if (bisac.posMoved < 0) {
    bisac.positionTag = DOWN;
  }

  if (bisac.posMoved === 0) {
    bisac.posMoved = '-';
    bisac.positionTag = EQUAL;
  }
}

function findBaseBisac (baseBisacs, bisac) {
  let baseIndex = -1;

  const foundBaseBisac = baseBisacs.find((baseBisac, i) => {
    if (bisac.code === baseBisac.code) {
      baseIndex = i;
      return baseBisac;
    }

    return undefined;
  });

  return { foundBaseBisac, baseIndex };
}
