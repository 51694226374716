import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'react-bootstrap';
import { seriesService } from '../../../services/seriesService';
import { titleService } from '../../../services/titleService';
import { Carousel } from '../carousel';
import { ListWithModal } from '../list-with-modal';
import { COMPLETE_MODE } from '../../../constants/constants';

export const TabContent = (props) => {
  const { cardItem, titles, titlesData, selectedTab, mode } = props;
  const orderedTitles = seriesService.orderTitles(titles);

  const in_catalog_books = mode === COMPLETE_MODE ? seriesService.countInCatalog(titles, selectedTab) : 0;

  return (
    <Tab.Content>
          <Tab.Pane key={selectedTab} eventKey={selectedTab}>
            <div className="panel">
              {
                mode === COMPLETE_MODE &&
                <div className="title">{`${in_catalog_books}/${orderedTitles.length} titles`}</div>
              }
              <div className="p-2">
                <Carousel show={4}>
                  <ListWithModal
                    Component={cardItem}
                    array={titles}
                    searchArray={titlesData}
                    findItem={'flrids'}
                    selectedTab={selectedTab}
                    findTitleFunction={titleService.findSerieTitleByFlrid}
                  />
                </Carousel>
              </div>
            </div>
          </Tab.Pane>
    </Tab.Content>
  );
};

TabContent.propTypes = {
  mode: PropTypes.string,
  cardItem: PropTypes.func,
  titles: PropTypes.array,
  titlesData: PropTypes.array,
  selectedTab: PropTypes.string
};
