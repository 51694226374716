import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import './styles.custom-select-option.scss';

export const CustomSelectOption = (props) => {
  return (
    <>
    <components.Option {...props}>
      {props.children}
      <br></br>
      <span className='select-district-name'>
        {props.data.district}
      </span>
      </components.Option>
    </>
  );
};

CustomSelectOption.propTypes = {
  children: PropTypes.any,
  data: PropTypes.any
};
