import React from 'react';
import PropTypes from 'prop-types';
import { ListWithModal } from '../../atoms/list-with-modal';
import { RecommendationItem } from '../../atoms/rec-item';
import { titleService } from '../../../services/titleService';

import './styles.rec-list.scss';

export const RecommendationList = (props) => {
  const { recommendations, titlesData, viewType, useTitleKeyCheck } = props;

  return (
    <slot className={`rec-list ${viewType}`}>
      {titlesData.length > 0 && recommendations && (
        <ListWithModal
          Component={RecommendationItem}
          componentProps={{ viewType }}
          array={recommendations}
          searchArray={titlesData}
          findItem={useTitleKeyCheck ? 'isbn_13' : 'flrid'}
          findTitleFunction={useTitleKeyCheck ? titleService.findSerieTitleByIsbn13orKey : titleService.findSerieTitleByFlrid2}
          withModal={true}
        />
      )}
    </slot>
  );
};

RecommendationList.propTypes = {
  recommendations: PropTypes.array,
  titlesData: PropTypes.array,
  viewType: PropTypes.string,
  useTitleKeyCheck: PropTypes.bool
};
