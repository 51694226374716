import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import noTitleThumbnail from '../../../../../public/images/titleNotFound.png';

import './styles.collapse.card.item.scss';

export const CollapseCardItem = (props) => {
  const { rowItemIndex, title, image_url, fil, onClick, isOpen } = props;

  const handleOnClick = () => {
    onClick(rowItemIndex);
  };

  return (
    <Card
      className={isOpen ? 'collapse-open' : ''}
      variant="top"
      onClick={() => {
        handleOnClick();
      }}
    >
      <Card.Img
        className="collapse-card-image"
        src={image_url}
        onLoad={(a) => {
          if (a.target.naturalWidth === 1) {
            a.target.src = noTitleThumbnail;
          }
        }}
        onError={(a) => {
          a.target.src = noTitleThumbnail;
        }}
      />
      <Card.Body>
        <Card.Title className="text-truncate text-center">{title}</Card.Title>
        <Card.Text className="text-truncate text-center card-fil">
          <div className="fil">{fil}</div>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

CollapseCardItem.propTypes = {
  rowItemIndex: PropTypes.number,
  title: PropTypes.string,
  image_url: PropTypes.string,
  fil: PropTypes.string,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool
};
