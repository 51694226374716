import React from 'react';
import PropTypes from 'prop-types';
import './styles.compare-statistics-item.scss';

export const CompareStatisticsItem = (props) => {
  const { item, tag } = props;

  return (
    <div className="compare-list-item">
      <div className={`score-${tag}`}>{item}</div>
    </div>
  );
};

CompareStatisticsItem.propTypes = {
  item: PropTypes.any,
  tag: PropTypes.string
};
