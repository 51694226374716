import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Stack from 'react-bootstrap/Stack';
import { modelService } from '../../../services/modelService';

import './styles.dashboard-toolbar.scss';

export const TrendingToolBar = (props) => {
  const {
    modelOptions,
    selectedModel,
    onChangeModel,
    modelTypeOptions,
    selectedType,
    onChangeModelType
  } = props;

  const modelOptionValue = selectedModel
    ? modelService.mapModelToOption(selectedModel)
    : undefined;

  return (
    <Navbar bg="light">
      <Container fluid>
        <Stack direction="horizontal" gap={3}>
          <span>Model type:</span>
          <Select
            value={selectedType}
            className="basic-single"
            classNamePrefix="select"
            options={modelTypeOptions}
            isSearchable={true}
            isClearable={false}
            onChange={onChangeModelType}
          />
          <span>Model:</span>
          <Select
            value={modelOptionValue}
            className="basic-single"
            classNamePrefix="select"
            options={modelService.getModelOptions(modelOptions)}
            isSearchable={true}
            isClearable={false}
            onChange={onChangeModel}
          />
        </Stack>
      </Container>
    </Navbar>
  );
};

TrendingToolBar.propTypes = {
  modelOptions: PropTypes.array,
  selectedModel: PropTypes.object,
  onChangeModel: PropTypes.func,
  modelTypeOptions: PropTypes.array,
  selectedType: PropTypes.object,
  onChangeModelType: PropTypes.func
};
