import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Stack from 'react-bootstrap/Stack';
import { TitlesSelect } from '../../atoms/titlesSelect';
import { TitleModal } from '../../molecules/titleModal';

import './styles.similar-titles-toolbar.scss';

export const SimilarTitlesToolBar = (props) => {
  const {
    typeOptions,
    selectedType,
    onChangeType,
    onChangeIsbn,
    selectedIsbnData
  } = props;

  function getType () {
    if (selectedType && selectedType[0]) {
      return selectedType[0].value;
    }
  }

  const handleShow = (titleData) => {
    if (selectedIsbnData && Object.keys(selectedIsbnData).length > 0) {
      setModalTitleData(selectedIsbnData);
      setShowModalTitle(true);
    }
  };
  const [showModalTitle, setShowModalTitle] = useState(false);
  const [modalTitleData, setModalTitleData] = useState();
  const handleClose = () => setShowModalTitle(false);

  return (
    <Navbar bg="light">
      <Container fluid>
        <Stack direction="horizontal" gap={3}>
          <TitlesSelect vectorType={getType()} onChangeIsbn={onChangeIsbn}></TitlesSelect>
          <div><button onClick={handleShow} disabled={!selectedIsbnData || Object.keys(selectedIsbnData).length === 0}>
          Title Details for {(selectedIsbnData && Object.keys(selectedIsbnData).length > 0) ? selectedIsbnData.title : ''}
          </button></div>
          <TitleModal
        show={showModalTitle}
        titleData={modalTitleData}
        handleClose={handleClose}
      />
          <span>Type:</span>
          <Select
            closeMenuOnSelect={false}
            isMulti
            value={selectedType}
            className="basic-single"
            classNamePrefix="select"
            options={typeOptions}
            isSearchable={true}
            isClearable={true}
            onChange={onChangeType}
          />
        </Stack>
      </Container>
    </Navbar>
  );
};

SimilarTitlesToolBar.propTypes = {
  typeOptions: PropTypes.array,
  selectedType: PropTypes.array,
  onChangeType: PropTypes.func,
  onChangeIsbn: PropTypes.func,
  selectedIsbnData: PropTypes.object
};
