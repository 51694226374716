import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.scss';

import { AxiosInterceptor } from './axios/interceptors';
import { LogIn } from './components/pages/login';
import { ProvideAuth } from './context/AuthContext';
import { PrivateRoute } from './routes/PrivateRoute';
import { Dashboard } from './components/pages/dashboard';
import { CompareModels } from './components/pages/compare';
import { DistrictStats } from './components/pages/site_stats';
import { TrendingTitles } from './components/pages/trending-titles';
import { NewSeries } from './components/pages/new-series';
import { CompleteSeries } from './components/pages/complete-series';
import { SimilarTitles } from './components/pages/similar-titles';

function App () {
  return (
    <div className="app">
      <ProvideAuth>
        <BrowserRouter>
          <AxiosInterceptor>
            <Routes>
              <Route path="/" element={<LogIn />} />
              <Route
                path="/dashboard"
                element={<PrivateRoute Component={<Dashboard />} />}
              />
              <Route
                path="/compare"
                element={<PrivateRoute Component={<CompareModels />} />}
              />
              <Route
                path="/site_stats"
                element={<PrivateRoute Component={<DistrictStats />} />}
              />
              <Route
                path="/complete-series"
                element={<PrivateRoute Component={<CompleteSeries />} />}
              />
              <Route
                path="/new-series"
                element={<PrivateRoute Component={<NewSeries />} />}
              />
              <Route
                path="/trending-titles"
                element={<PrivateRoute Component={<TrendingTitles />} />}
              />
              <Route
                path="/similar-titles"
                element={<PrivateRoute Component={<SimilarTitles />} />}
              />
            </Routes>
          </AxiosInterceptor>
        </BrowserRouter>
      </ProvideAuth>
    </div>
  );
}

export default App;
