import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Stack from 'react-bootstrap/Stack';
import { Button } from 'react-bootstrap';
import { ViewSelect } from '../../atoms/viewSelect';
import { MetricsModal } from '../metricsModal';
import { modelService } from '../../../services/modelService';

import './styles.dashboard-toolbar.scss';

export const DashboardToolBar = (props) => {
  const {
    modelOptions,
    selectedModel,
    onChangeModel,
    modelTypeOptions,
    selectedType,
    onChangeModelType,
    topNOptions,
    selectedTopN,
    onChangeTopN,
    gradeOptions,
    selectedGrades,
    onChangeGrades,
    languageOptions,
    selectedLanguages,
    onChangeLanguages,
    pubYearOptions,
    selectedFromPubYear,
    onChangeFromPubYear,
    selectedToPubYear,
    onChangeToPubYear,
    reviewCountOptions,
    selectedReviewCount,
    onChangeReviewCount,
    sourceOptions,
    selectedSource,
    onChangeSource
  } = props;

  const [showModalMetrics, setShowModalMetrics] = useState(false);

  const modelOptionValue = selectedModel
    ? modelService.mapModelToOption(selectedModel)
    : undefined;

  const handleClose = () => setShowModalMetrics(false);

  return (
    <Navbar bg="light">
      <Container fluid>
        <Stack direction="horizontal" gap={1}>
          <ViewSelect />
        </Stack>
        {/* <Stack direction="horizontal" gap={1}> */}
        <div className="filter-options" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1rem' }}>
          <div>
            <span>Top N:</span>
            <Select
              value={topNOptions.find(option => option.value === selectedTopN)}
              className="basic-single small"
              classNamePrefix="select"
              options={topNOptions}
              isSearchable={false}
              isClearable={false}
              onChange={onChangeTopN}
            />
          </div>

          <div>
            <span>Grades:</span>
            <Select
              value={gradeOptions.find(option => option.value === selectedGrades[0])}
              className="basic-single small"
              classNamePrefix="select"
              options={gradeOptions}
              isSearchable={false}
              isClearable={false}
              onChange={onChangeGrades}
            />
          </div>

          <div>
            <span>Languages:</span>
            <Select
              value={languageOptions.find(option => option.value === selectedLanguages[0])}
              className="basic-single small"
              classNamePrefix="select"
              options={languageOptions}
              isSearchable={false}
              isClearable={false}
              onChange={onChangeLanguages}
            />
          </div>

          <div>
            <span>From Pub Year:</span>
            <Select
              value={pubYearOptions.find(option => option.value === selectedFromPubYear)}
              className="basic-single small"
              classNamePrefix="select"
              options={pubYearOptions}
              isSearchable={false}
              isClearable={false}
              onChange={onChangeFromPubYear}
            />
          </div>

          <div>
            <span>To Pub Year:</span>
            <Select
              value={pubYearOptions.find(option => option.value === selectedToPubYear)}
              className="basic-single small"
              classNamePrefix="select"
              options={pubYearOptions}
              isSearchable={false}
              isClearable={false}
              onChange={onChangeToPubYear}
            />
          </div>

          <div>
            <span>Review Count:</span>
            <Select
              value={reviewCountOptions.find(option => option.value === selectedReviewCount)}
              className="basic-single small"
              classNamePrefix="select"
              options={reviewCountOptions}
              isSearchable={false}
              isClearable={false}
              onChange={onChangeReviewCount}
            />
          </div>

          <div>
            <span>Source:</span>
            <Select
              value={sourceOptions.find(option => option.value === selectedSource)}
              className="basic-single small"
              classNamePrefix="select"
              options={sourceOptions}
              isSearchable={false}
              isClearable={false}
              onChange={onChangeSource}
            />
          </div>

          <div>
            <span>Model type:</span>
            <Select
              value={selectedType}
              className="basic-single"
              classNamePrefix="select"
              options={modelTypeOptions}
              isSearchable={true}
              isClearable={false}
              onChange={onChangeModelType}
            />
          </div>

          <div>
            <span>Model:</span>
            <Select
              value={modelOptionValue}
              className="basic-single"
              classNamePrefix="select"
              options={modelService.getModelOptions(modelOptions)}
              isSearchable={true}
              isClearable={false}
              onChange={onChangeModel}
            />
          </div>

          <Button variant="outline-primary" onClick={setShowModalMetrics}>
            Model Metrics
          </Button>
        </div>
        {/* </Stack> */}
      </Container>

      {selectedModel && (
        <MetricsModal
          model={selectedModel}
          modelName={'modelOptionValue.label'}
          show={showModalMetrics}
          handleClose={handleClose}
        />
      )}
    </Navbar>
  );
};

DashboardToolBar.propTypes = {
  modelOptions: PropTypes.array,
  selectedModel: PropTypes.object,
  onChangeModel: PropTypes.func,
  modelTypeOptions: PropTypes.array,
  selectedType: PropTypes.object,
  onChangeModelType: PropTypes.func,
  topNOptions: PropTypes.array,
  selectedTopN: PropTypes.number,
  onChangeTopN: PropTypes.func,
  gradeOptions: PropTypes.array,
  selectedGrades: PropTypes.array,
  onChangeGrades: PropTypes.func,
  languageOptions: PropTypes.array,
  selectedLanguages: PropTypes.array,
  onChangeLanguages: PropTypes.func,
  pubYearOptions: PropTypes.array,
  selectedFromPubYear: PropTypes.number,
  onChangeFromPubYear: PropTypes.func,
  selectedToPubYear: PropTypes.number,
  onChangeToPubYear: PropTypes.func,
  reviewCountOptions: PropTypes.array,
  selectedReviewCount: PropTypes.string,
  onChangeReviewCount: PropTypes.func,
  sourceOptions: PropTypes.array,
  selectedSource: PropTypes.string,
  onChangeSource: PropTypes.func
};
