import React from 'react';
import PropTypes from 'prop-types';
import './styles.compare-keys-item.scss';

export const CompareKeysItem = (props) => {
  const { itemKey } = props;

  return (
    <div className="compare-list-keys">
      <div>{itemKey}</div>
    </div>
  );
};

CompareKeysItem.propTypes = {
  itemKey: PropTypes.string
};
