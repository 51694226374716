import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { TitleModal } from '../../molecules/titleModal';

export const ListWithModal = (props) => {
  const {
    Component,
    componentProps,
    array,
    searchArray,
    findItem,
    findTitleFunction,
    selectedTab // works only for series
  } = props;

  const [showModalTitle, setShowModalTitle] = useState(false);
  const [modalTitleData, setModalTitleData] = useState();

  const getList = () => {
    return array.map((item, index) => {
      const titleData = findTitleFunction(item[findItem], searchArray, selectedTab);

      const props = {
        ...componentProps,
        index,
        item,
        titleData,
        selectedTab,
        handleShow
      };

      return <Component key={index} {...props} />;
    });
  };

  const handleShow = (titleData) => {
    setShowModalTitle(true);
    setModalTitleData(titleData);
  };

  const handleClose = () => setShowModalTitle(false);

  return (
    <Fragment>
      {getList()}
      <TitleModal
        show={showModalTitle}
        titleData={modalTitleData}
        handleClose={handleClose}
      />
    </Fragment>
  );
};

ListWithModal.propTypes = {
  Component: PropTypes.func,
  componentProps: PropTypes.object,
  array: PropTypes.array,
  searchArray: PropTypes.array,
  findItem: PropTypes.string,
  findTitleFunction: PropTypes.func,
  selectedTab: PropTypes.string
};

ListWithModal.defaultProps = {
  selectedTab: '-1'
};
