import React from 'react';
import PropTypes from 'prop-types';
import { CompareKeysItem } from '../../atoms/compare-keys-item';

export const CompareKeysList = (props) => {
  const { keySet } = props;

  return <div className="keys-container">{getList(keySet)}</div>;
};

function getList (keySet) {
  return keySet.map((itemKey) => {
    return <CompareKeysItem key={itemKey} itemKey={itemKey} />;
  });
}

CompareKeysList.propTypes = {
  keySet: PropTypes.array
};
