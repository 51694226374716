import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Stack from 'react-bootstrap/Stack';
import { Button } from 'react-bootstrap';
import { ViewSelect } from '../../atoms/viewSelect';
import { MetricsModal } from '../metricsModal';
import { modelService } from '../../../services/modelService';

import './styles.stats-toolbar.scss';

export const StatsToolBar = (props) => {
  const {
    modelOptions,
    selectedModel,
    onChangeModel,
    modelTypeOptions,
    selectedType,
    onChangeModelType
  } = props;

  const [showModalMetrics, setShowModalMetrics] = useState(false);

  const modelOptionValue = selectedModel
    ? modelService.mapModelToOption(selectedModel)
    : undefined;

  const handleClose = () => setShowModalMetrics(false);

  return (
    <Navbar bg="light">
      <Container fluid>
        <Stack direction="horizontal" gap={2}>
          <ViewSelect />
        </Stack>
        <Stack direction="horizontal" gap={3}>
          <span>Model type:</span>

          <Select
            value={selectedType}
            className="basic-single"
            classNamePrefix="select"
            options={modelTypeOptions}
            isSearchable={true}
            isClearable={false}
            onChange={onChangeModelType}
          />
          <span>Model:</span>

          <Select
            value={modelOptionValue}
            className="basic-single"
            classNamePrefix="select"
            options={modelService.getModelOptions(modelOptions)}
            isSearchable={true}
            isClearable={false}
            onChange={onChangeModel}
          />

          <Button variant="outline-primary" onClick={setShowModalMetrics}>
            Model Metrics
          </Button>
        </Stack>
      </Container>

      {selectedModel && (
        <MetricsModal
          model={selectedModel}
          modelName={'modelOptionValue.label'}
          show={showModalMetrics}
          handleClose={handleClose}
        />
      )}
    </Navbar>
  );
};

StatsToolBar.propTypes = {
  modelOptions: PropTypes.array,
  selectedModel: PropTypes.object,
  onChangeModel: PropTypes.func,
  modelTypeOptions: PropTypes.array,
  selectedType: PropTypes.object,
  onChangeModelType: PropTypes.func
};
