import { useContext, useMemo } from 'react';
import axios from 'axios';
import { authContext } from '../context/AuthContext';
import { authenticationService } from '../services/authenticationService';
import { ID_TOKEN, ACCESS_TOKEN } from '../constants/constants';
import { SERVER_REQUEST_HOST } from '../constants/enviroment';

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function addSubscriber (callback) {
  subscribers.push(callback);
}

function onAccessTokenFetched (accessToken, idToken) {
  subscribers = subscribers.forEach((callback) =>
    callback(accessToken, idToken)
  );
}

const client = axios.create({
  baseURL: SERVER_REQUEST_HOST
});

const AxiosInterceptor = ({ children }) => {
  const { isAuth, updateTokens, removeAuth } = useContext(authContext);

  useMemo(() => {
    client.interceptors.request.use(
      (config) => {
        config.headers['Content-Type'] = 'application/json';

        if (isAuth) {
          const accessToken = window.localStorage.getItem(ACCESS_TOKEN);
          const IdToken = window.localStorage.getItem(ID_TOKEN);

          config.headers.Authorization = `Bearer ${accessToken}`;
          config.headers[ID_TOKEN] = IdToken;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    client.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const originalConfig = error.config;

        // Access Token was expired
        if (error.response?.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;
            authenticationService
              .refreshToken()
              .then((result) => {
                const { accessToken, idToken } = result;
                updateTokens(accessToken, idToken);
                onAccessTokenFetched(accessToken, idToken);
              })
              .catch((_error) => {
                if (
                  isAlreadyFetchingAccessToken &&
                  (_error.status === 500 || _error.status === 403)
                ) {
                  alert('User session expired');
                  removeAuth();
                  return;
                }

                return Promise.reject(_error);
              })
              .finally(() => {
                isAlreadyFetchingAccessToken = false;
                subscribers = [];
              });
          }

          const retryOriginalRequest = new Promise((resolve) => {
            addSubscriber((accessToken, idToken) => {
              originalConfig.headers = { ...originalConfig.headers };
              originalConfig.headers.Authorization = accessToken;
              originalConfig.headers[ID_TOKEN] = idToken;
              resolve(client(originalConfig));
            });
          });

          return retryOriginalRequest;
        }

        if (error.response?.status === 403) {
          console.error('error', error);
          alert('Invalid credentials');
          removeAuth();
          return;
        }

        return Promise.reject(error);
      }
    );
  }, [isAuth]);

  return children;
};

export { client };
export { AxiosInterceptor };
