import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import * as _ from 'lodash';
import { titleService } from '../../../services/titleService';
import { authContext } from '../../../context/AuthContext';
import './styles.titles-select.scss';

export const TitlesSelect = (props) => {
  const {
    vectorType,
    onChangeIsbn
  } = props;

  const { siteGuid } = useContext(authContext);
  const [selectedValue, setSelectedValue] = useState(null);
  const _loadOptions = (searchValue, callback) => {
    titleService.getTitlesByTerm(searchValue, vectorType, siteGuid).then((resp) => {
      callback(resp);
    });
  };

  const loadOptions = _.debounce(_loadOptions, 1500);

  const onChangeTitle = (value) => {
    setSelectedValue(value);
    onChangeIsbn(value);
  };

  return (
    <div>
      <div>
        <label>Title:</label>
        <AsyncSelect
          value={selectedValue}
          className="basic-single title-select"
          classNamePrefix="select"
          loadOptions={loadOptions}
          onChange={onChangeTitle}
          // defaultOptions
        />
      </div>
    </div>
  );
};

TitlesSelect.propTypes = {
  vectorType: PropTypes.string,
  onChangeIsbn: PropTypes.func
};
