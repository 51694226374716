import React, { useState, useContext, useEffect, Fragment } from 'react';
import { DashboardToolBar } from '../../molecules/dashboard-toolbar';
// import { CheckoutsPanel } from '../../organisms/panel-checkouts';
import { RecommendationsPanel } from '../../organisms/panel-recs';
// import { SimilarSitePanel } from '../../organisms/panel-sim-site';
import { authContext } from '../../../context/AuthContext';
import { titleService } from '../../../services/titleService';
import { useModels } from '../../../hooks/useModels';
import { recommendationService } from '../../../services/recommendationService';
import {
  ML_TARGET,
  POPULARITY,
  FLRID_FILTER,
  TOP_N_OPTIONS,
  GRADE_OPTIONS,
  LANGUAGE_OPTIONS,
  PUB_YEAR_OPTIONS,
  REVIEW_COUNT_OPTIONS,
  SOURCE_OPTIONS
} from '../../../constants/constants';
import { Spinner } from '../../atoms/spinner';

import './styles.dashboard.scss';

export const Dashboard = () => {
  const { siteGuid, appId } = useContext(authContext);

  const {
    models,
    modelTypeOptions,
    modelsBasedOnType,
    updateModelTypeOptions,
    updteModelsBasedOnType
  } = useModels();
  const [model, setModel] = useState();
  const [modelType, setModelType] = useState();
  const [topN, setTopN] = useState(50);
  const [grades, setGrades] = useState(['all']);
  const [languages, setLanguages] = useState(['all']);
  const [fromPubYear, setFromPubYear] = useState(0);
  const [toPubYear, setToPubYear] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [source, setSource] = useState('all');
  const [recommendationData, setRecommendationData] = useState({});
  const [titlesData, setTitlesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const updateTopN = (selectedOption) => {
    setTopN(selectedOption.value);
  };

  const updateGrades = (selectedOption) => {
    setGrades([selectedOption.value]);
  };

  const updateLanguages = (selectedOption) => {
    setLanguages([selectedOption.value]);
  };

  const updateFromPubYear = (selectedOption) => {
    setFromPubYear(selectedOption.value);
  };

  const updateToPubYear = (selectedOption) => {
    setToPubYear(selectedOption.value);
  };

  const updateReviewCount = (selectedOption) => {
    setReviewCount(selectedOption.value);
  };

  const updateSource = (selectedOption) => {
    setSource(selectedOption.value);
  };

  const updateModel = (option) => {
    const newModel = models.find((model) => {
      return model.guid === option.value;
    });

    setModel(newModel);
  };

  const updateTypeModel = (option) => {
    setModelType(option);
  };

  useEffect(
    function () {
      updateModelTypeOptions(ML_TARGET, [POPULARITY]);
    },
    [models]
  );

  useEffect(
    function () {
      setModelType(modelTypeOptions[0]);
    },
    [modelTypeOptions]
  );

  useEffect(
    function () {
      const defaultType = modelType ? modelType.value : undefined;
      updteModelsBasedOnType(ML_TARGET, defaultType);
    },
    [modelType]
  );

  useEffect(
    function () {
      setModel(modelsBasedOnType[0]);
    },
    [modelsBasedOnType]
  );

  useEffect(
    function () {
      if (model) {
        setLoading(true);
        recommendationService
          .getRecommendations(model, siteGuid, appId, topN, grades, languages, fromPubYear, toPubYear, reviewCount, source)
          .then((data) => {
            setErrorMessage();
            setRecommendationData(data);
            return titleService.getFlridArray(data.recs);
          })
          .then((flridArray) => {
            return titleService.getTitlesByFilter(flridArray, FLRID_FILTER, siteGuid);
          })
          .then((data) => {
            setTitlesData(data);
          })
          .catch((error) => {
            console.error(error);
            setErrorMessage('Could not fetch recommendations for this site, Please try another model or site');
            setRecommendationData([]);
            setTitlesData([]);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [model, siteGuid, topN, grades, languages, fromPubYear, toPubYear, reviewCount, source] // empty-once, set variables-refresh based on var changes
  );

  return (
    <Fragment>
      <DashboardToolBar
        modelOptions={modelsBasedOnType}
        selectedModel={model}
        onChangeModel={updateModel}
        modelTypeOptions={modelTypeOptions}
        selectedType={modelType}
        onChangeModelType={updateTypeModel}
        topNOptions={TOP_N_OPTIONS}
        selectedTopN={topN}
        onChangeTopN={updateTopN}
        gradeOptions={GRADE_OPTIONS}
        selectedGrades={grades}
        onChangeGrades={updateGrades}
        languageOptions={LANGUAGE_OPTIONS}
        selectedLanguages={languages}
        onChangeLanguages={updateLanguages}
        pubYearOptions={PUB_YEAR_OPTIONS}
        selectedFromPubYear={fromPubYear}
        onChangeFromPubYear={updateFromPubYear}
        selectedToPubYear={toPubYear}
        onChangeToPubYear={updateToPubYear}
        reviewCountOptions={REVIEW_COUNT_OPTIONS}
        selectedReviewCount={reviewCount}
        onChangeReviewCount={updateReviewCount}
        sourceOptions={SOURCE_OPTIONS}
        selectedSource={source}
        onChangeSource={updateSource}
      />
      {!loading && (
        <div className="main">
          {/* <CheckoutsPanel
            titlesData={titlesData}
            checkouts={recommendationData.checkouts}
          /> */}

          <RecommendationsPanel
            titlesData={titlesData}
            recommendations={recommendationData.recs}
            isDefaultRecs={false}
            errorMessage={errorMessage}
          />

          {/* <SimilarSitePanel sites={recommendationData.similar_sites} />  */}
        </div>
      )}
      {loading && <Spinner></Spinner>}
    </Fragment>
  );
};
