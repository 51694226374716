import React, { useEffect, useState, useContext } from 'react';
import { CustomSelectOption } from '../../custom-select-option';
import AsyncSelect from 'react-select/async';
import * as _ from 'lodash';
import { siteService } from '../../../services/siteService';
import { authContext } from '../../../context/AuthContext';
import './styles.site-select.scss';

const _loadOptions = (searchValue, callback) => {
  siteService.getSites(searchValue).then((resp) => {
    callback(resp);
  });
};

const loadOptions = _.debounce(_loadOptions, 1500);

export const SitesSelect = (props) => {
  const { setLocation, siteGuid, siteName, districtName } = useContext(authContext);
  const [isDisabled, setDisable] = useState(true);

  useEffect(function () {
    const words = siteName.split(' ');
    _loadOptions(`${words[0]}`, checkDisable);
  }, []);

  const checkDisable = (response) => {
    if (response.length > 0) {
      setDisable(false);
    }
  };

  const onChangeSite = (site) => {
    setLocation(site.value, site.label, site.district, site.fscCustomerNo);
  };

  return (
    <div>
      <div>
      <label>Site:</label>
    <AsyncSelect
      defaultValue={{ value: siteGuid, label: siteName, district: districtName }}
      className="basic-single"
      classNamePrefix="select"
      isSearchable={true}
      isClearable={false}
      isDisabled={isDisabled}
      loadOptions={loadOptions}
      onChange={onChangeSite}
      components={{ Option: CustomSelectOption }}
    />
    </div>
    <div>
        <span className='custom-district-name'>
          {districtName}
        </span>
      </div>
    </div>
  );
};
