import React from 'react';
import { CompareStatisticsItem } from '../../atoms/compare-statistics-item';
import { compareMetricsService } from '../../../services/compareMetricsService';
import { modelService } from '../../../services/modelService';

export const CompareStatisticsList = (props) => {
  const { compareMatrix, keySet, statisticsAttribute } = props;

  return getList(compareMatrix, keySet, statisticsAttribute);
};

function getList (compareMatrix, keySet, statisticsAttribute) {
  let previous = null;

  return compareMatrix.map((model) => {
    const item = compareMetricsService.formatItem(
      { ...model[statisticsAttribute] },
      keySet
    );
    const itemCompared = compareMetricsService.compareItem(
      { ...item },
      previous,
      keySet
    );
    previous = { ...item };

    return (
      <div key={statisticsAttribute + model.guid} className="panel">
        <div className="title">{modelService.getModelName(model)}</div>
        {getItem(keySet, itemCompared, statisticsAttribute)}
      </div>
    );
  });
}

function getItem (keySet, itemCompared, statisticsAttribute) {
  if (keySet.length > 0) {
    return keySet.map((key, index) => {
      return (
        <CompareStatisticsItem
          key={index}
          item={itemCompared[key].value}
          tag={itemCompared[key].tag}
        />
      );
    });
  }

  return <div>{`model without ${statisticsAttribute}`}</div>;
}
