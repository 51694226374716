import React from 'react';
import PropTypes from 'prop-types';
import { BisacItem } from '../../atoms/bisac-item';

import './styles.bisac-list.scss';

export const BisacList = (props) => {
  const { model } = props;

  return <div className="bisac-list">{getList(model)}</div>;
};

function getList (model) {
  const maxBisacValue = getMaxBisacValue(model);

  if (model && model.bisacs) {
    return buildComponentList(model, maxBisacValue);
  }

  return [];
}

function getMaxBisacValue (model) {
  return model.bisacs && model.bisacs.length > 0
    ? Number(model.bisacs[0].count)
    : 0;
}

function buildComponentList (model, maxBisacValue) {
  return model.bisacs.map((item, index) => {
    return (
      <BisacItem
        key={item.code}
        index={index}
        bisac={item}
        meter={getMeter(item.count, maxBisacValue)}
      />
    );
  });
}

function getMeter (count, maxCheckoutValue) {
  return (Number(count) / maxCheckoutValue) * 100;
}

BisacList.propTypes = {
  model: PropTypes.object
};
