import React from 'react';
import PropTypes from 'prop-types';
import { METRICS_TAB } from '../../../constants/constants';
import { CompareKeysList } from '../../molecules/compare-keys-list';
import { CompareStatisticsList } from '../../molecules/compare-statistics-list';

import './styles.panel-compare-statistics.scss';

export const PanelCompareStatistics = (props) => {
  const { compareMatrix, keySet, statisticsAttribute } = props;

  return (
    <div className="panel-compare-statistics">
      <div className="compare-metrics-params">
        <CompareKeysList keySet={keySet} />

        <div className="model-values-container">
          <CompareStatisticsList
            compareMatrix={compareMatrix}
            keySet={keySet}
            statisticsAttribute={statisticsAttribute}
          />
        </div>
      </div>

      {compareMatrix && statisticsAttribute === METRICS_TAB
        ? (
        <div className="legend">
          <span>Train Coverage</span>
          <span>
            % of titles that the model is recommending from the training set.
          </span>
          <span>Sell Coverage</span>
          <span>
            % of titles that the model is recommending from all the sellable
            titles.
          </span>
          <span>Novelty</span>
          <span>
            Inverse of popularity. Measures the capacity of our system to
            propose novel and unexpected titles which a site is unlikely to know
            about already. A high value indicates we are recommending titles
            that are less frequently seen on other site recommendations.
          </span>
          <span>Personalization</span>
          <span>
            Personalization is a way to analyse if a model recommends many of
            the same titles to different sites. A low value indicates that
            recommendations are the same / similar for many sites. A high value
            is desired as it indicates that recommendations are different across
            sites - personalised for individual sites.
          </span>
          <span>Model ILS (Intra-List Similarity)</span>
          <span>
            Similar to personalization. It measures similarity of
            recommendations at the site level. Based on features of titles. How
            similar or different the recommended titles are for a site. ILS is
            calculated for each site, and averaged over all sites in the dataset
            to get an estimate of ILS for the model. Low value can be good or
            bad - depending on your point of view - do you want similar or
            diverse recommendations?
          </span>
        </div>
          )
        : null}
    </div>
  );
};

PanelCompareStatistics.propTypes = {
  compareMatrix: PropTypes.array,
  keySet: PropTypes.array,
  statisticsAttribute: PropTypes.string
};
