import React, { Fragment, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate, useLocation } from 'react-router-dom';
import { VIEW_OPTION } from '../../../constants/constants';

export const ViewSelect = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [viewOption] = useState(() => {
    const localStoragePath = window.localStorage.getItem(VIEW_OPTION);
    if (localStoragePath && !location.pathname.includes(window.localStorage.getItem(VIEW_OPTION))) {
      navigate(window.localStorage.getItem(VIEW_OPTION), { replace: true });
      return navigate(0);
    };
    return window.localStorage.getItem(VIEW_OPTION);
  });

  const onClickOption = (event) => {
    const option = event.target.value;
    window.localStorage.setItem(VIEW_OPTION, option);
    navigate(`/${option}`);
  };

  return (
    <Fragment>
      <span>View:</span>
      <Form.Select
        name="model-selection"
        id="model-selection"
        onChange={onClickOption}
        defaultValue={viewOption}
      >
        <option value="dashboard">Recommendations</option>
        <option value="compare">Compare</option>
        <option value="site_stats">Site Stats</option>
      </Form.Select>
    </Fragment>
  );
};
