import React, { Fragment, useState, useEffect, useContext } from 'react';
import {
  ISBN_FILTER, KEY_FILTER, SIMILAR_TITLES_TARGET, OTHERS_ALSO_READ_TARGET
} from '../../../constants/constants';
import { authContext } from '../../../context/AuthContext';
import { titleService } from '../../../services/titleService';
import { recommendationService } from '../../../services/recommendationService';
import { Spinner } from '../../atoms/spinner';
import { SimilarTitlesToolBar } from '../../molecules/similar-titles-toolbar';
// import { RecommendationsPanel } from '../../organisms/panel-recs';
import { CompareRecsList } from '../../molecules/compare-recs-list';
import { compareRecsService } from '../../../services/compareRecsService';
import { modelService } from '../../../services/modelService';

import './styles.similar-titles.scss';

export const SimilarTitles = () => {
  const { siteGuid, fscCustomerNo } = useContext(authContext);
  const [isbn_13, setIsbn13] = useState();
  const [typeOptions, setTypeOptions] = useState([]);
  const [selectedIsbnData, setSelectedIsbnData] = useState({});
  const [types, setTypes] = useState([typeOptions[0]]);
  const [compareMatrix, setCompareMatrix] = useState([]);
  const [recommendationData, setRecommendationData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const updateRecommendation = (selectedOption) => {
    setIsbn13(selectedOption);
  };

  const updateType = (selectedOption) => {
    setTypes(selectedOption);
  };

  useEffect(() => {
    modelService
      .getModels([SIMILAR_TITLES_TARGET, OTHERS_ALSO_READ_TARGET])
      .then((data) => {
        const typeOptions = data.map((x) => {
          return { label: x.name, value: x.guid };
        });
        setTypeOptions(typeOptions);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(
    function () {
      setCompareMatrix([]);
      setErrorMessage();
      let recommendationData;
      if (isbn_13 && types && types[0]) {
        setLoading(true);
        const modelNames = [];
        const promisesToFire = types.map((type) => {
          modelNames.push(type.label);
          return recommendationService
            .getSimilarTitles(isbn_13.value, type.value, siteGuid, fscCustomerNo);
        });
        Promise.all(promisesToFire)
          .then((data) => {
            setErrorMessage();
            recommendationData = data;
            let isbnArray = [isbn_13.value];
            data.forEach((rec) => {
              isbnArray = isbnArray.concat(titleService.getIsbnArray(rec.recs));
            });
            const isbnArrayU = new Set(isbnArray);
            return [...isbnArrayU];
          })
          .then((isbnArray) => {
            return Promise.all([titleService.getTitlesByFilter(isbnArray, ISBN_FILTER, siteGuid),
              titleService.getTitlesByFilter(isbnArray, KEY_FILTER, siteGuid)]);
          })
          .then(([isbnData, keyData]) => {
            const dataMap = isbnData.reduce((map, obj) => {
              map[obj.isbn_13] = obj;
              return map;
            }, {});
            const keyDataMap = keyData.reduce((map, obj) => {
              map[obj.key] = obj;
              return map;
            }, {});
            setSelectedIsbnData(dataMap[isbn_13.value]);

            recommendationData.forEach((recData, i) => {
              recData.guid = recData.model_guid;
              recData.name = modelNames[i];
              recData.recs.forEach(rec => {
                let titleFound = dataMap[rec.isbn_13];
                // console.log('titleFound', titleFound);
                if (titleFound && titleFound.follett_interest_level) {
                  rec.fil = [titleFound.follett_interest_level];
                } else {
                  titleFound = keyDataMap[rec.isbn_13];
                  if (titleFound && titleFound.follett_interest_level) {
                    rec.fil = [titleFound.follett_interest_level];
                  }
                }
              });
            });
            const recommendationsResults = compareRecsService.compareRecommendations(recommendationData);
            // console.log('recommendationsResults', recommendationsResults);
            if (recommendationsResults && recommendationsResults[0]) {
              setCompareMatrix(recommendationsResults);
            } else {
              setErrorMessage('Could not fetch recommendations for this site, Please try another model or site');
            }
            setRecommendationData(keyData.concat(isbnData));
          })
          .catch((error) => {
            console.error(error);
            setErrorMessage('Could not fetch recommendations for this site, Please try another model or site');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [siteGuid, isbn_13, types]
  );

  return (
    <Fragment>
      <SimilarTitlesToolBar
        onChangeType={updateType}
        selectedType={types}
        typeOptions={typeOptions}
        selectedIsbnData={selectedIsbnData}
        onChangeIsbn={updateRecommendation}
      />
      {loading && <div>Please wait, we are fetching similar recommendations for your title. <br/><Spinner></Spinner></div>}
      { errorMessage &&
              (<div className='error-container'>
                <p className='error-msg'>{errorMessage}</p>
                </div>
              )}
      {!loading && (
                    <CompareRecsList
                    compareMatrix={compareMatrix}
                    titlesData={recommendationData}
                  />
      )}
    </Fragment>
  );
};
