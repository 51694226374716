import React from 'react';
import PropTypes from 'prop-types';
import { BOOK_IMAGE_URL } from '../../../constants/enviroment';
import noTitleThumbnail from '../../../../../public/images/titleNotFound.png';

import './styles.compare-item.scss';

export const CompareItem = (props) => {
  const { item, titleData, index, handleShow } = props;

  const source = item.rec_source ? item.rec_source.join(', ') : '';

  function scoreToFixed (item) {
    return item && item.score ? item.score.toFixed(4) : 0;
  }
  return (
    <div>
      <div
        className={`pos ${item.positionTag} ${item.posMoved == null ? 'base' : ''
          }`}
      >
        <span className="now">{index + 1}</span>

        {item.posMoved && <span className="state">{item.posMoved}</span>}

        <span className={`score-${item.scoreTag}`}>{scoreToFixed(item)}</span>
      </div>

      <div>
        <img
          src={`${BOOK_IMAGE_URL}${item.isbn_13}`}
          onClick={() => handleShow(titleData)}
          onLoad={(a) => {
            if (a.target.naturalWidth === 1) {
              a.target.src = noTitleThumbnail;
            }
          }}
          onError={(a) => {
            a.target.src = noTitleThumbnail;
          }}
        />
      </div>

      <div>
        <div>
          <span>{titleData?.title}</span>
          <span className={`s${titleData?.status}`}>{mapStatus(titleData?.status) || '-'}</span>
        </div>

        <div>
          <span>{item.isbn_13}</span>
        </div>

        <div>
          <span>{titleData?.author || '-'}</span>,
          <span>{titleData?.illustrator || '-'}</span>
        </div>

        <div>
          {titleData?.publication_year || '-'},
          {titleData?.language || '-'},
          {titleData?.format || '-'},
          {titleData?.publisher_name || '-'}
        </div>

        <div>
          {item.fil && item.fil.length > 0
            ? (
                item.fil.map((fil) => {
                  return (
                <span key={fil} className="fil">{fil}</span>
                  );
                })
              )
            : (
            <span className="fil">-</span>
              )}
          {source && (
            <span className="rec-source">{source} pool</span>
          )}
          <span>{titleData?.is_fiction ? 'fiction' : 'non-fiction' || ''}</span>
        </div>

        <div>
          {titleData?.subjects &&
            titleData.subjects.map((subject, index) => {
              return (
                <span key={subject}>{subject}</span>
              );
            })
          }
        </div>

        <div>
          {titleData?.bisacs &&
            titleData.bisacs.map((bisac, index) => {
              let desc = bisac.description.replace(
                /^JUVENILE NONFICTION \//i,
                'JNF /'
              );
              desc = desc.replace(/^JUVENILE FICTION \//i, 'JF /');
              desc = desc.replace(/^YOUNG ADULT FICTION \//i, 'YAF /');
              const headingIndex = desc.lastIndexOf('/', desc.lastIndexOf('/') - 1) + 1;
              return (
                <span key={bisac.description}>{desc.substring(headingIndex)}</span>
              );
            })
          }
        </div>

      </div>
    </div>
  );
};

function mapStatus (status) {
  switch (status) {
    case 0:
      return 'Deleted';
    case 1:
      return 'Pre-Sales';
    case 2:
      return 'Non-sellable';
    case 3:
      return 'Sellable';
    default:
      return 'Unknown';
  }
}

CompareItem.propTypes = {
  item: PropTypes.object,
  titleData: PropTypes.object,
  index: PropTypes.number,
  handleShow: PropTypes.func
};
