import React, { Fragment, useState, useEffect, useContext } from 'react';
import {
  ALS,
  ISBN_FILTER,
  ML_TARGET,
  WIDE_AND_DEEP
} from '../../../constants/constants';
import { authContext } from '../../../context/AuthContext';
import { useModels } from '../../../hooks/useModels';
import { titleService } from '../../../services/titleService';
import { trendingService } from '../../../services/trendingService';
import { Spinner } from '../../atoms/spinner';
import { TrendingToolBar } from '../../molecules/trending-toolbar';
import { RecommendationsPanel } from '../../organisms/panel-recs';

import './styles.trending-titles.scss';

export const TrendingTitles = () => {
  const {
    models,
    modelTypeOptions,
    modelsBasedOnType,
    updateModelTypeOptions,
    updteModelsBasedOnType
  } = useModels();
  const { siteGuid } = useContext(authContext);
  const [model, setModel] = useState();
  const [modelType, setModelType] = useState();
  const [recommendationData, setRecommendationData] = useState({});
  const [titlesData, setTitlesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const updateModel = (option) => {
    const newModel = models.find((model) => {
      return model.guid === option.value;
    });

    setModel(newModel);
  };

  const updateTypeModel = (option) => {
    setModelType(option);
  };

  useEffect(
    function () {
      updateModelTypeOptions(ML_TARGET, [ALS, WIDE_AND_DEEP]);
    },
    [models]
  );

  useEffect(
    function () {
      setModelType(modelTypeOptions[0]);
    },
    [modelTypeOptions]
  );

  useEffect(
    function () {
      const defaultType = modelType ? modelType.value : undefined;
      updteModelsBasedOnType(ML_TARGET, defaultType);
    },
    [modelType]
  );

  useEffect(
    function () {
      setModel(modelsBasedOnType[0]);
    },
    [modelsBasedOnType]
  );

  useEffect(
    function () {
      if (model) {
        setLoading(true);
        trendingService
          .getTrendingRecs(model, siteGuid)
          .then((data) => {
            setErrorMessage();
            setRecommendationData(data);
            return titleService.getIsbnArray(data.recs);
          })
          .then((isbnArray) => {
            return titleService.getTitlesByFilter(isbnArray, ISBN_FILTER, siteGuid);
          })
          .then((data) => {
            setTitlesData(data);
          })
          .catch((error) => {
            console.error(error);
            setTitlesData([]);
            setErrorMessage('Could not fetch recommendations for this site, Please try another model or site');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [siteGuid, model]
  );

  return (
    <Fragment>
      {loading && <Spinner></Spinner>}
      <TrendingToolBar
        modelOptions={modelsBasedOnType}
        selectedModel={model}
        onChangeModel={updateModel}
        modelTypeOptions={modelTypeOptions}
        selectedType={modelType}
        onChangeModelType={updateTypeModel}
      />
      {!loading && (
        <div className="trending">
          <RecommendationsPanel
            titlesData={titlesData}
            recommendations={recommendationData.recs}
            isDefaultRecs={false}
            errorMessage={errorMessage}
          />
        </div>
      )}
    </Fragment>
  );
};
