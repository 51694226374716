import React from 'react';
import PropTypes from 'prop-types';
import { modelService } from '../../../services/modelService';
import { BisacList } from '../../molecules/bisac-list';

import './styles.panel-bisac.scss';

export const BisacPanel = (props) => {
  const { model } = props;

  return (
    <div className="panel bisac">
      <div className="title">{modelService.getModelName(model)}</div>
      <div className="content">
        <BisacList model={model} />
      </div>
    </div>
  );
};

BisacPanel.propTypes = {
  model: PropTypes.object
};
